import { categoryConstants as constants } from '../constants';
import { categoryServices as services } from '../services';
import { doAction} from './action.base';

export const categoryActions = {
    getAll,
    getDetail,
    save,
    deleteItem,
    cancelChange
};

function getAll() {
    return doAction(services.getAll(),
        constants.GET_ALL_REQUEST, constants.GET_ALL_SUCCESS, constants.GET_ALL_FAILURE
    )
}

function getDetail(id) {
    return doAction(services.getDetail(id),
        constants.GET_DETAIL_ITEM_REQUEST, constants.GET_DETAIL_ITEM_SUCCESS, constants.GET_DETAIL_ITEM_FAILURE
    )
}

function save(dataObj) {
    return doAction(services.save(dataObj),
        constants.SAVE_ITEM_REQUEST, constants.SAVE_ITEM_SUCCESS, constants.SAVE_ITEM_FAILURE
    )
}

function deleteItem(id) {
    return doAction(services.deleteItem(id),
        constants.DELETE_ITEM_REQUEST, constants.DELETE_ITEM_SUCCESS, constants.DELETE_ITEM_FAILURE
    )
}

function cancelChange() {
    return dispatch => dispatch({ type: constants.CANCEL_CHANGE });
}