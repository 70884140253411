export const DayOfWeek = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5, 
    Saturday: 6    
};

export const MonthOfYear = [
    { value: 1, text: "January" },
    { value: 2, text: "February" },
    { value: 3, text: "March" },
    { value: 4, text: "April" },
    { value: 5, text: "May" },
    { value: 6, text: "June" },
    { value: 7, text: "July" },
    { value: 8, text: "August" },
    { value: 9, text: "September" },
    { value: 10, text: "October" },
    { value: 11, text: "November" },
    { value: 12, text: "December" },
];

export const DayOfMonth = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
    11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31
]