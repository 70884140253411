export const companyProfileConstants = {

    GET_ALL_REQUEST: 'COMPANY_PROFILE_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'COMPANY_PROFILE_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'COMPANY_PROFILE_GET_ALL_FAILURE',

    CHANGE_WORKDAY: 'COMPANY_PROFILE_CHANGE_WORKDAY',

    SAVE_ITEM_REQUEST: 'COMPANY_PROFILE_SAVE_ITEM_REQUEST',
    SAVE_ITEM_SUCCESS: 'COMPANY_PROFILE_SAVE_ITEM_SUCCESS',
    SAVE_ITEM_FAILURE: 'COMPANY_PROFILE_SAVE_ITEM_FAILURE',

    GET_STATES_REQUEST: 'GET_STATES_REQUEST',
    GET_STATES_SUCCESS: 'GET_STATES_SUCCESS',
    GET_STATES_FAILURE: 'GET_STATES_FAILURE',

    GET_LANGUAGES_REQUEST: 'GET_LANGUAGES_REQUEST',
    GET_LANGUAGES_SUCCESS: 'GET_LANGUAGES_SUCCESS',
    GET_LANGUAGES_FAILURE: 'GET_LANGUAGES_FAILURE',

    GET_TIME_INTERVALS_REQUEST: 'GET_TIME_INTERVALS_REQUEST',
    GET_TIME_INTERVALS_SUCCESS: 'GET_TIME_INTERVALS_SUCCESS',
    GET_TIME_INTERVALS_FAILURE: 'GET_TIME_INTERVALS_FAILURE',

    CANCEL_CHANGE: 'CANCEL_CHANGE'
};