const FEATURE = 'STORES';

export const setStoreConstants = {
    storeReducer: "stores",
    gridId: "gridstores",
    homeUrl: "/stores",
    addUrl: "/stores",
    editUrl: "/stores/",
    importUrl: "#",

    GET_ALL_REQUEST: `GET_ALL_${FEATURE}_REQUEST`,
    GET_ALL_SUCCESS: `GET_ALL_${FEATURE}_SUCCESS`,
    GET_ALL_FAILURE: `GET_ALL_${FEATURE}_FAILURE`,

    SET_STORE_REQUEST: `SET_STORE_${FEATURE}_REQUEST`,
    SET_STORE_SUCCESS: `SET_STORE_${FEATURE}_SUCCESS`,
    SET_STORE_FAILURE: `SET_STORE_${FEATURE}_FAILURE`,

    GET_DETAIL_ITEM_REQUEST: `GET_DETAIL_${FEATURE}_REQUEST`,
    GET_DETAIL_ITEM_SUCCESS: `GET_DETAIL_${FEATURE}_SUCCESS`,
    GET_DETAIL_ITEM_FAILURE: `GET_DETAIL_${FEATURE}_FAILURE`,

    SAVE_ITEM_REQUEST: `SAVE_${FEATURE}_REQUEST`,
    SAVE_ITEM_SUCCESS: `SAVE_${FEATURE}_SUCCESS`,
    SAVE_ITEM_FAILURE: `SAVE_${FEATURE}_FAILURE`,

    //DELETE_ITEM_REQUEST: `DELETE_${FEATURE}_REQUEST`,
    //DELETE_ITEM_SUCCESS: `DELETE_${FEATURE}_SUCCESS`,
    //DELETE_ITEM_FAILURE: `DELETE_${FEATURE}_FAILURE`,
    
    CANCEL_CHANGE: `${FEATURE}_CANCEL_CHANGE`
};