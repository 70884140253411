import { appointmentConstants as constants, DayOfWeek } from '../constants';
import { appointmentServices as services } from '../services';
import { doAction } from './action.base';
import { utils } from '../core';

export const appointmentActions = {
    getRetentionTypes,
    getAppointments,
    getAppointment,
    getEmployeeWorkHours,
    saveAppointment,
    saveBlockedTime,
    updateAppointmentStatus,
    updateAppointmentConfirmStatus,
    //getAppointmentByCustomer,
    dragUpdateAppointment,
    getApptLogs,
    clearAppointmentHistory,
    getUnassignAppointments,
    getCancelNoShowAppointments,
    checkConflictAppointment,
    assignAppointment,
    cancelChange,
    getResources,
    confirmOnlineAppts,
    copyAppointment,
    moveAppointment,
    deleteAppointment,
    exportToExcel,
    importFromExcel,
    getDataExcel,
    downloadFileExcel,
    getOnlineAppointments,
    getAppointmentsByDate,
    rejectOnlineAppt
};

function exportToExcel(connectionId) {
    return doAction(services.exportToExcel(connectionId),
        constants.EXPORT_DATA_REQUEST, constants.EXPORT_DATA_SUCCESS, constants.EXPORT_DATA_FAILURE
    )
}

function importFromExcel(dataSource) {
    return doAction(services.importFromExcel(dataSource),
        constants.IMPORT_DATA_REQUEST, constants.IMPORT_DATA_SUCCESS, constants.IMPORT_DATA_FAILURE
    )
}

function getDataExcel(fileName) {
    return doAction(services.getDataExcel(fileName),
        constants.GET_DATA_EXCEL_REQUEST, constants.GET_DATA_EXCEL_SUCCESS, constants.GET_DATA_EXCEL_FAILURE
    )
}

function downloadFileExcel(fileUrl) {
    return doAction(services.downloadFileExcel(fileUrl),
        constants.EXPORT_DATA_REQUEST, constants.EXPORT_DATA_SUCCESS, constants.EXPORT_DATA_FAILURE
    )
}

function deleteAppointment(apptId) {
    return doAction(services.deleteAppointment(apptId),
        constants.DELETE_APPOINTMENT_REQUEST,
        constants.DELETE_APPOINTMENT_SUCCESS,
        constants.DELETE_APPOINTMENT_FAILURE
    )
}

function copyAppointment(apptId, userId, timeSlot) {
    return doAction(services.copyAppointment(apptId, userId, timeSlot),
        constants.COPY_APPOINTMENT_REQUEST,
        constants.COPY_APPOINTMENT_SUCCESS,
        constants.COPY_APPOINTMENT_FAILURE
    )
}

function moveAppointment(apptId, apptItemId, userId, timeSlot) {
    return doAction(services.moveAppointment(apptId, apptItemId, userId, timeSlot),
        constants.MOVE_APPOINTMENT_REQUEST,
        constants.MOVE_APPOINTMENT_SUCCESS,
        constants.MOVE_APPOINTMENT_FAILURE
    )
}

function getResources() {
    return doAction(services.getResources(),
        constants.GET_APPOINTMENT_RESOURCES_REQUEST,
        constants.GET_APPOINTMENT_RESOURCES_SUCCESS,
        constants.GET_APPOINTMENT_RESOURCES_FAILURE
    )
}

function assignAppointment(apptId, userId) {
    return doAction(services.assignAppointment(apptId, userId),
        constants.ASSIGN_APPOINTMENT_REQUEST,
        constants.ASSIGN_APPOINTMENT_SUCCESS,
        constants.ASSIGN_APPOINTMENT_FAILURE
    )
}

function checkConflictAppointment(appointment) {
    return doAction(services.checkConflictAppointment(appointment),
        constants.CHECK_CONFLICT_APPOINTMENTS_REQUEST,
        constants.CHECK_CONFLICT_APPOINTMENTS_SUCCESS,
        constants.CHECK_CONFLICT_APPOINTMENTS_FAILURE
    )
}

function getUnassignAppointments(start, end) {
    return doAction(services.getUnassignAppointments(start, end),
        constants.GET_UNASSIGN_APPOINTMENTS_REQUEST,
        constants.GET_UNASSIGN_APPOINTMENTS_SUCCESS,
        constants.GET_UNASSIGN_APPOINTMENTS_FAILURE
    )
}

function getCancelNoShowAppointments(start, end) {
    return doAction(services.getCancelNoShowAppointments(start, end),
        constants.GET_CANCEL_NOSHOW_APPOINTMENTS_REQUEST,
        constants.GET_CANCEL_NOSHOW_APPOINTMENTS_SUCCESS,
        constants.GET_CANCEL_NOSHOW_APPOINTMENTS_FAILURE
    )
}

function dragUpdateAppointment(appointment) {
    return doAction(services.dragUpdateAppointment(appointment),
        constants.DRAG_UPDATE_APPOINTMENT_REQUEST,
        constants.DRAG_UPDATE_APPOINTMENT_SUCCESS,
        constants.DRAG_UPDATE_APPOINTMENT_FAILURE
    )
}

//function getAppointmentByCustomer(customerId) {
//    return doAction(services.getAppointmentByCustomer(customerId),
//        constants.GET_APPOINTMENTS_BY_CUSTOMER_REQUEST,
//        constants.GET_APPOINTMENTS_BY_CUSTOMER_SUCCESS,
//        constants.GET_APPOINTMENTS_BY_CUSTOMER_FAILURE
//    )
//}

function updateAppointmentConfirmStatus(appointment) {
    return doAction(services.updateAppointmentConfirmStatus(appointment),
        constants.UPDATE_APPOINTMENT_CONFIRM_STATUS_REQUEST,
        constants.UPDATE_APPOINTMENT_CONFIRM_STATUS_SUCCESS,
        constants.UPDATE_APPOINTMENT_CONFIRM_STATUS_FAILURE
    )
}

function updateAppointmentStatus(appointment) {
    return doAction(services.updateAppointmentStatus(appointment),
        constants.UPDATE_APPOINTMENT_STATUS_REQUEST,
        constants.UPDATE_APPOINTMENT_STATUS_SUCCESS,
        constants.UPDATE_APPOINTMENT_STATUS_FAILURE
    )
}

function saveBlockedTime(appts) {
    return doAction(services.saveBlockedTime(appts),
        constants.SAVE_BLOCKED_TIME_REQUEST,
        constants.SAVE_BLOCKED_TIME_SUCCESS,
        constants.SAVE_BLOCKED_TIME_FAILURE
    )
}

function saveAppointment(appointment) {
    return doAction(services.saveAppointment(appointment),
        constants.SAVE_APPOINTMENTS_REQUEST,
        constants.SAVE_APPOINTMENTS_SUCCESS,
        constants.SAVE_APPOINTMENTS_FAILURE
    )
}

function getEmployeeWorkHours(apptDate, employee) {
    let day = apptDate.getDay();
    let fromHour, toHour, isAvailable;
    let workHours = employee.workHours;    

    switch (day) {
        case DayOfWeek.Monday: 
            {
                isAvailable = workHours.isMon;
                fromHour = workHours.monFromHour;
                toHour = workHours.monToHour;
            }

            break;

        case DayOfWeek.Tuesday:
            {
                isAvailable = workHours.isTue;
                fromHour = workHours.tueFromHour;
                toHour = workHours.tueToHour;
            }

            break;

        case DayOfWeek.Wednesday:
            {
                isAvailable = workHours.isWed;
                fromHour = workHours.wedFromHour;
                toHour = workHours.wedToHour;
            }

            break;

        case DayOfWeek.Thursday:
            {
                isAvailable = workHours.isThu;
                fromHour = workHours.thuFromHour;
                toHour = workHours.thuToHour;                
            }

            break;

        case DayOfWeek.Friday:
            {
                isAvailable = workHours.isFri;
                fromHour = workHours.friFromHour;
                toHour = workHours.friToHour;
            }

            break;

        case DayOfWeek.Saturday:
            {
                isAvailable = workHours.isSat;
                fromHour = workHours.satFromHour;
                toHour = workHours.satToHour;
            }

            break;

        case DayOfWeek.Sunday:
            {
                isAvailable = workHours.isSun;
                fromHour = workHours.sunFromHour;
                toHour = workHours.sunToHour;
            }

            break;
    }
    
    let start = utils.convertToDateTime(fromHour);
    let end = utils.convertToDateTime(toHour);

    let from = new Date(apptDate.getFullYear(), apptDate.getMonth(), apptDate.getDate(), start.getHours(), start.getMinutes());
    let to = new Date(apptDate.getFullYear(), apptDate.getMonth(), apptDate.getDate(), end.getHours(), end.getMinutes());

    return [isAvailable, from, to];
}

function getAppointments(userId, start, end) {
    return doAction(services.getAppointments(userId, start, end),
        constants.GET_APPOINTMENTS_REQUEST,
        constants.GET_APPOINTMENTS_SUCCESS,
        constants.GET_APPOINTMENTS_FAILURE
    )
}

function getAppointment(apptId) {
    return doAction(services.getAppointment(apptId),
        constants.GET_ITEM_APPOINTMENT_REQUEST,
        constants.GET_ITEM_APPOINTMENT_SUCCESS,
        constants.GET_ITEM_APPOINTMENT_FAILURE
    )
}

function getRetentionTypes() {    
    return doAction(services.getRetentionTypes(),
        constants.GET_RETENTION_REQUEST,
        constants.GET_RETENTION_SUCCESS,
        constants.GET_RETENTION_FAILURE
    )
}

function getApptLogs(date) {
    return doAction(services.getApptLogs(date),
        constants.GET_APPTLOGS_REQUEST,
        constants.GET_APPTLOGS_SUCCESS,
        constants.GET_APPTLOGS_FAILURE
    )
}

function clearAppointmentHistory() {
    return dispatch => dispatch({ type: constants.CLEAR_APPOINTMENT_HISTORY });
}

function cancelChange() {
    return dispatch => dispatch({ type: constants.CANCEL_CHANGE });
}

function confirmOnlineAppts(apptIds) {
    return doAction(services.confirmOnlineAppts(apptIds),
        constants.CONFIRM_ONLINE_APPTS_REQUEST,
        constants.CONFIRM_ONLINE_APPTS_SUCCESS,
        constants.CONFIRM_ONLINE_APPTS_FAILURE
    )
}

function getOnlineAppointments() {
    return doAction(services.getOnlineAppointments(),
        constants.GET_ONLINE_APPT_REQUEST,
        constants.GET_ONLINE_APPT_SUCCESS,
        constants.GET_ONLINE_APPT_FAILURE
    )
}

function getAppointmentsByDate(date) {
    return doAction(services.getAppointmentsByDate(date),
        constants.GET_APPOINTMENTS_BY_DATE_REQUEST,
        constants.GET_APPOINTMENTS_BY_DATE_SUCCESS,
        constants.GET_APPOINTMENTS_BY_DATE_FAILURE
    )
}

function rejectOnlineAppt(apptId) {
    return doAction(services.rejectOnlineAppt(apptId),
        constants.REJECT_ONLINE_APPT_REQUEST,
        constants.REJECT_ONLINE_APPT_SUCCESS,
        constants.REJECT_ONLINE_APPT_FAILURE
    )
}