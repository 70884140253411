import { authHeader } from './common';

export function clearCached() {
    localStorage.removeItem("companyProfile");
    localStorage.removeItem("dataStorage");
}

export function getCache(key) {
    let dataStorage = JSON.parse(localStorage.getItem('dataStorage'));

    if (dataStorage && dataStorage[key] && dataStorage[key].length !== 0) {
        return dataStorage[key];
    }

    return null;
}

export function setCache(key, value) {
    if (value === null || value === undefined)
        return;

    let dataStorage = localStorage.getItem('dataStorage');
    dataStorage = dataStorage ? JSON.parse(dataStorage) : {};
    dataStorage[key] = value;
    localStorage.setItem("dataStorage", JSON.stringify(dataStorage));
}

export async function getCollection(url) {
    return await get(url);
}

export async function getItem(url) {
    return await get(url);
}

export async function removeItem(url) {
    return await post(url);
}

export async function saveItem(url, data) {
    return await post(url, data);
}

export async function validate(url, data) {
    return await post(url, data);
}

async function get(url) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let response = await fetch(url, requestOptions);
    let data = await response.json();

    return data;
}

export async function exportToExcel(url) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return await fetch(url, requestOptions).then(handleResponse);
}

export async function importFromExcel(url, dataSource) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(dataSource)           
        
    };

    return await fetch(url, requestOptions).then(handleResponse);
}

export async function postDataObj(url, dataObj) {
    return await post(url, dataObj);
}

async function post(url, dataObj) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: (dataObj !== null && dataObj !== undefined ? JSON.stringify(dataObj) : {})
    };

    return await fetch(url, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}