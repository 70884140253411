import { appointmentConstants as constants } from '../constants';

const initialState = {
    dataSource: [],
    isFetching: false,
    isForceReload: true,
    errors: {},
    dataItem: null,
    retentionTypes: [],
    apptLogs: [],
    onlineAppts: [],
    appointmentsByDate: [],
    exportResult: {}
}

export function appointmentReducer(state = {}, action) {
    state = state || initialState;

    switch (action.type) {

        /*******************************************************/
        case constants.GET_RETENTION_REQUEST:
            return {
                ...state,
                isFetching: true
            };

        case constants.GET_RETENTION_SUCCESS:
            return {
                ...state,
                isFetching: false,
                retentionTypes: action.payload
            };

        case constants.GET_RETENTION_FAILURE:
            return {
                isFetching: false,
                error: action.error,
            };

        /*******************************************************/
        case constants.GET_APPOINTMENT_RESOURCES_REQUEST:
            return {
                ...state,
            };

        case constants.GET_APPOINTMENT_RESOURCES_SUCCESS:
            return {
                ...state,
                employees: action.payload
            };

        case constants.GET_APPOINTMENT_RESOURCES_FAILURE:
            return {
                error: action.error,
            };

        /*******************************************************/
        case constants.GET_APPOINTMENTS_REQUEST:
            return {
                ...state,
                isFetching: true,
                dataSource: null,
                responseResult: null,
                appointmentItem: null,
                rejectOnlineApptResult: null
            };

        case constants.GET_APPOINTMENTS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                isForceReload: false,
                dataSource: action.payload,
                errors: {
                    status: true,
                    message: "OK"
                },
            };

        case constants.GET_APPOINTMENTS_FAILURE:
            return {
                isFetching: false,
                errors: {
                    status: false,
                    message: action.error
                },
            };

        /*******************************************************/
        case constants.GET_ITEM_APPOINTMENT_REQUEST:
            return {
                ...state,
                isFetching: true,
                //appointmentItem: null,
                responseResult: null
            };

        case constants.GET_ITEM_APPOINTMENT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                responseResult: action.payload,
                errors: {
                    status: true,
                    message: "OK"
                },
            };

        case constants.GET_ITEM_APPOINTMENT_FAILURE:
            return {
                isFetching: false,
                errors: {
                    status: false,
                    message: action.error
                },
            };

        /*******************************************************/
        case constants.SAVE_APPOINTMENTS_REQUEST:
            return {
                ...state,
                conflictResult: null,
                isFetching: true,
                responseResult: null
            };

        case constants.SAVE_APPOINTMENTS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                isForceReload: true,
                appointmentItem: null,
                responseResult: action.payload
            };

        case constants.SAVE_APPOINTMENTS_FAILURE:
            return {
                isFetching: false,
                responseResult: null,
                errors: {
                    status: false,
                    message: action.error
                }
            };

        /*******************************************************/
        case constants.SAVE_BLOCKED_TIME_REQUEST:
        case constants.DRAG_UPDATE_APPOINTMENT_REQUEST:
        case constants.DELETE_APPOINTMENT_REQUEST:
            return {
                ...state,
                isFetching: true
            };

        case constants.SAVE_BLOCKED_TIME_SUCCESS:
        case constants.DRAG_UPDATE_APPOINTMENT_SUCCESS:
        case constants.DELETE_APPOINTMENT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                isForceReload: true,
                responseResult: action.payload
            };

        case constants.SAVE_BLOCKED_TIME_FAILURE:
        case constants.DRAG_UPDATE_APPOINTMENT_FAILURE:
        case constants.DELETE_APPOINTMENT_FAILURE:
            return {
                isFetching: false,
                responseResult: null,
                errors: {
                    status: false,
                    message: action.error
                }
            };

        /*******************************************************/
        case constants.UPDATE_APPOINTMENT_STATUS_REQUEST:
            return {
                ...state,
                isFetching: true
            };

        case constants.UPDATE_APPOINTMENT_STATUS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                isForceReload: true,
                responseResult: action.payload
            };

        case constants.UPDATE_APPOINTMENT_STATUS_FAILURE:
            return {
                isFetching: false,
                responseResult: null,
                errors: {
                    status: false,
                    message: action.error
                }
            };

        /*******************************************************/
        case constants.UPDATE_APPOINTMENT_CONFIRM_STATUS_REQUEST:
            return {
                ...state,
                isFetching: true
            };

        case constants.UPDATE_APPOINTMENT_CONFIRM_STATUS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                isForceReload: true,
                responseResult: action.payload
            };

        case constants.UPDATE_APPOINTMENT_CONFIRM_STATUS_FAILURE:
            return {
                isFetching: false,
                responseResult: null,
                errors: {
                    status: false,
                    message: action.error
                }
            };

        /*******************************************************/
        case constants.GET_APPOINTMENTS_BY_CUSTOMER_REQUEST:
            return {
                ...state,
                appointmentHistory: null,
                isFetching: true,
            };

        case constants.GET_APPOINTMENTS_BY_CUSTOMER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                appointmentHistory: action.payload,
                errors: {
                    status: true,
                    message: "OK"
                },
            };

        case constants.GET_APPOINTMENTS_BY_CUSTOMER_FAILURE:
            return {
                isFetching: false,
                errors: {
                    status: false,
                    message: action.error
                },
            };
        /*******************************************************/
        case constants.GET_APPTLOGS_REQUEST:
            return {
                ...state,
                isFetching: true
            };

        case constants.GET_APPTLOGS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                apptLogs: action.payload
            };

        case constants.GET_APPTLOGS_FAILURE:
            return {
                isFetching: false,
                errors: {
                    status: false,
                    message: action.error
                }
            };

        /*******************************************************/
        case constants.GET_UNASSIGN_APPOINTMENTS_REQUEST:
            return {
                ...state,
                responseResult: null,
                isFetching: true
            };

        case constants.GET_UNASSIGN_APPOINTMENTS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                unassignedAppointments: action.payload,
                errors: {
                    status: true,
                    message: "OK"
                },
            };

        case constants.GET_UNASSIGN_APPOINTMENTS_FAILURE:
            return {
                isFetching: false,
                errors: {
                    status: false,
                    message: action.error
                },
            };

        /*******************************************************/
        case constants.GET_CANCEL_NOSHOW_APPOINTMENTS_REQUEST:
            return {
                ...state,
                responseResult: null,
                isFetching: true
            };

        case constants.GET_CANCEL_NOSHOW_APPOINTMENTS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                cancelNoShowAppointments: action.payload,
                errors: {
                    status: true,
                    message: "OK"
                },
            };

        case constants.GET_CANCEL_NOSHOW_APPOINTMENTS_FAILURE:
            return {
                isFetching: false,
                errors: {
                    status: false,
                    message: action.error
                },
            };

        /*******************************************************/
        case constants.CHECK_CONFLICT_APPOINTMENTS_REQUEST:
            return {
                ...state,
                conflictResult: null,
                isFetching: true
            };

        case constants.CHECK_CONFLICT_APPOINTMENTS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                conflictResult: action.payload
            };

        case constants.CHECK_CONFLICT_APPOINTMENTS_FAILURE:
            return {
                isFetching: false,
                errors: {
                    status: false,
                    message: action.error
                },
            };

        /*******************************************************/
        case constants.ASSIGN_APPOINTMENT_REQUEST:
            return {
                ...state,
                responseResult: null,
                isFetching: true
            };

        case constants.ASSIGN_APPOINTMENT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                isForceReload: true,
                responseResult: action.payload
            };

        case constants.ASSIGN_APPOINTMENT_FAILURE:
            return {
                isFetching: false,
                errors: {
                    status: false,
                    message: action.error
                },
            };

        /*******************************************************/

        case constants.CONFIRM_ONLINE_APPTS_REQUEST:
            return {
                ...state,
                responseResult: null,
                isFetching: true
            };

        case constants.CONFIRM_ONLINE_APPTS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                responseResult: action.payload
            };

        case constants.CONFIRM_ONLINE_APPTS_FAILURE:
            return {
                isFetching: false,
                errors: {
                    status: false,
                    message: action.error
                },
            };

        /*******************************************************/

        case constants.COPY_APPOINTMENT_REQUEST:
        case constants.MOVE_APPOINTMENT_REQUEST:
            return {
                ...state,
                responseResult: null,
                isFetching: true
            };

        case constants.COPY_APPOINTMENT_SUCCESS:
        case constants.MOVE_APPOINTMENT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                responseResult: action.payload
            };

        case constants.COPY_APPOINTMENT_FAILURE:
        case constants.MOVE_APPOINTMENT_FAILURE:
            return {
                isFetching: false,
                errors: {
                    status: false,
                    message: action.error
                },
            };

        /********************************************************/
        case constants.GET_DATA_EXCEL_REQUEST:
            return {
                ...state,
                excelDataSource: null,
                isFetching: true
            };

        case constants.GET_DATA_EXCEL_SUCCESS:
            return {
                ...state,
                isFetching: false,
                excelDataSource: action.payload
            };

        case constants.GET_DATA_EXCEL_FAILURE:
            return {
                error: action.error,
                isFetching: false
            };

        /********************************************************/
        case constants.EXPORT_DATA_REQUEST:
            return {
                ...state,
                exportResult: null,
                isFetching: true
            };

        case constants.EXPORT_DATA_SUCCESS:
            return {
                ...state,
                isFetching: false,
                exportResult: action.payload
            };

        case constants.EXPORT_DATA_FAILURE:
            return {
                error: action.error,
                isFetching: false
            };

        /********************************************************/
        case constants.IMPORT_DATA_REQUEST:
            return {
                ...state,
                importResult: null,
                isFetching: true
            };

        case constants.IMPORT_DATA_SUCCESS:
            return {
                ...state,
                isFetching: false,
                isForceReload: true,
                importResult: action.payload
            };

        case constants.IMPORT_DATA_FAILURE:
            return {
                error: action.error,
                isFetching: false
            };

        /*******************************************************/

        case constants.CLEAR_APPOINTMENT_HISTORY:
            return {
                ...state,
                isFetching: false,
                appointmentHistory: null,
                responseResult: null,
            };

        /*******************************************************/
        case constants.GET_ONLINE_APPT_REQUEST:
            return {
                ...state,
                isFetching: true,
                onlineAppts: null
            };

        case constants.GET_ONLINE_APPT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                isForceReload: false,
                onlineAppts: action.payload,
                errors: {
                    status: true,
                    message: "OK"
                },
            };

        case constants.GET_ONLINE_APPT_FAILURE:
            return {
                isFetching: false,
                errors: {
                    status: false,
                    message: action.error
                },
            };


        /*******************************************************/
        case constants.GET_APPOINTMENTS_BY_DATE_REQUEST:
            return {
                ...state,
                appointmentsByDate: []
            };

        case constants.GET_APPOINTMENTS_BY_DATE_SUCCESS:
            return {
                ...state,
                appointmentsByDate: action.payload,
                errors: {
                    status: true,
                    message: "OK"
                },
            };

        case constants.GET_APPOINTMENTS_BY_DATE_FAILURE:
            return {
                errors: {
                    status: false,
                    message: action.error
                },
            };

        /*******************************************************/

        case constants.REJECT_ONLINE_APPT_REQUEST:
            return {
                ...state,
                rejectOnlineApptResult: null,
                isFetching: true
            };

        case constants.REJECT_ONLINE_APPT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                rejectOnlineApptResult: action.payload
            };

        case constants.REJECT_ONLINE_APPT_FAILURE:
            return {
                isFetching: false,
                errors: {
                    status: false,
                    message: action.error
                },
            };

        /*******************************************************/

        case constants.CANCEL_CHANGE:
            return {
                ...state,
                isFetching: false,
                appointmentItem: null,
                appointmentHistory: null,
                responseResult: null,
            };

        default:
            return state
    }
}