import { authHeader } from './common';

export const jobCodeService = {
    getAll,
    getItem,
    saveItem
};

async function getAll() {
    let url = `api/JobCode/GetAll`;
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return await fetch(url, requestOptions).then(handleResponse);
}

function getItem(id) {
    let url = `api/JobCode/GetItem/${id}`
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(url, requestOptions).then(handleResponse);
}

function saveItem(dataItem) {
    const requestOptions = {
        method: 'POST',
        'Content-Type': 'application/json',
        headers: authHeader(),
        body: JSON.stringify(dataItem)
    };

    return fetch("api/JobCode/CreateOrUpdate/", requestOptions).then(handleResponse);

}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}