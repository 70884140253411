import moment from 'moment';
import { getCollection } from './service.base';
const baseRoute = "api/runlogs";

export const runlogServices = {
    getAll
};

async function getAll(start, end) {
    let s = moment(start).format("MM-DD-YYYY");
    let e = moment(end).format("MM-DD-YYYY");

    return await getCollection(`${baseRoute}/get-logs/${s}/${e}`);
}