export const AppointmentStatus = [
    { value: "New", text: "New" },
    { value: "CheckIn", text: "CheckIn" },
    { value: "CheckOut", text: "CheckOut" },
    { value: "Cancel", text: "Cancel" },
    { value: "NoShow", text: "NoShow" },
];

export const AppointmentConfirmStatus = [
    { value: "Non", text: "None" },
    { value: "Phone", text: "Phone" },
    { value: "Voicemail", text: "Voicemail" },
    { value: "Email", text: "Email" },
    { value: "Sms", text: "Sms" },
];

//export const AppointmentConfirmStatus = [
//    { value: 0, text: "None" },
//    { value: 1, text: "Phone" },
//    { value: 2, text: "Voicemail" },
//    { value: 3, text: "Email" },
//    { value: 4, text: "Sms" },
//];

export const AppointmentTypes = {
    Regular: "Regular",
    HeldOnList: "HeldOnList",
    HeldOnBook: "HeldOnBook"
}