export const productConstants = {

    gridId: "gridProducts",
    homeUrl: '/product-services',
    addUrl : "/add-product",
    editUrl: "/edit-product/",

    GET_ALL_REQUEST: 'PRODUCT_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'PRODUCT_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'PRODUCT_GET_ALL_FAILURE',

    GET_DETAIL_ITEM_REQUEST: 'GET_DETAIL_PRODUCT_REQUEST',
    GET_DETAIL_ITEM_SUCCESS: 'GET_DETAIL_PRODUCT_SUCCESS',
    GET_DETAIL_ITEM_FAILURE: 'GET_DETAIL_PRODUCT_FAILURE',

    SAVE_ITEM_REQUEST: 'SAVE_PRODUCT_REQUEST',
    SAVE_ITEM_SUCCESS: 'SAVE_PRODUCT_SUCCESS',
    SAVE_ITEM_FAILURE: 'SAVE_PRODUCT_FAILURE',

    DELETE_ITEM_REQUEST: 'DELETE_PRODUCT_REQUEST',
    DELETE_ITEM_SUCCESS: 'DELETE_PRODUCT_SUCCESS',
    DELETE_ITEM_FAILURE: 'DELETE_PRODUCT_FAILURE',

    GET_MENU_ITEMS_REQUEST: 'PRODUCT_MENU_ITEMS_REQUEST',
    GET_MENU_ITEMS_SUCCESS: 'PRODUCT_MENU_ITEMS_SUCCESS',
    GET_MENU_ITEMS_FAILURE: 'PRODUCT_MENU_ITEMS_FAILURE',

    GET_MENU_ITEM_TYPES_REQUEST: 'GET_MENU_ITEM_TYPES_REQUEST',
    GET_MENU_ITEM_TYPES_SUCCESS: 'GET_MENU_ITEM_TYPES_SUCCESS',
    GET_MENU_ITEM_TYPES_FAILURE: 'GET_MENU_ITEM_TYPES_FAILURE',

    GET_DATA_EXCEL_REQUEST: 'GET_PRODUCTS_DATA_EXCEL_REQUEST',
    GET_DATA_EXCEL_SUCCESS: 'GET_PRODUCTS_DATA_EXCEL_SUCCESS',
    GET_DATA_EXCEL_FAILURE: 'GET_PRODUCTS_DATA_EXCEL_FAILURE',

    EXPORT_DATA_REQUEST: 'EXPORT_DATA_PRODUCTS_REQUEST',
    EXPORT_DATA_SUCCESS: 'EXPORT_DATA_PRODUCTS_SUCCESS',
    EXPORT_DATA_FAILURE: 'EXPORT_DATA_PRODUCTS_FAILURE',

    IMPORT_DATA_REQUEST: 'IMPORT_DATA_PRODUCTS_REQUEST',
    IMPORT_DATA_SUCCESS: 'IMPORT_DATA_PRODUCTS_SUCCESS',
    IMPORT_DATA_FAILURE: 'IMPORT_DATA_PRODUCTS_FAILURE',

    CANCEL_CHANGE: 'CANCEL_CHANGE'
};