import { productConstants as constants } from '../constants';
import { productServices as services } from '../services';
import { doAction } from './action.base';

export const productActions = {
    fetchData,
    getAllMenuItems,
    getMenuItemTypes,
    getDetail,
    save,
    deleteItem,
    getDataExcel,
    importData,
    exportItems
};

function exportItems() {
    return doAction(services.exportToExcel(),
        constants.EXPORT_DATA_REQUEST, constants.EXPORT_DATA_SUCCESS, constants.EXPORT_DATA_FAILURE
    )
}

function importData(dataSource) {
    return doAction(services.importFromExcel(dataSource),
        constants.IMPORT_DATA_REQUEST, constants.IMPORT_DATA_SUCCESS, constants.IMPORT_DATA_FAILURE
    )
}

function getDataExcel(fileName) {
    return doAction(services.getDataExcel(fileName),
        constants.GET_DATA_EXCEL_REQUEST, constants.GET_DATA_EXCEL_SUCCESS, constants.GET_DATA_EXCEL_FAILURE
    )
}

function fetchData() {
    return doAction(services.getAll(),
        constants.GET_ALL_REQUEST, constants.GET_ALL_SUCCESS, constants.GET_ALL_FAILURE
    )
}

function getAllMenuItems() {
    return doAction(services.getAllMenuItems(),
        constants.GET_MENU_ITEMS_REQUEST, constants.GET_MENU_ITEMS_SUCCESS, constants.GET_MENU_ITEMS_FAILURE
    )
}

function getDetail(id) {
    return doAction(services.getDetail(id),
        constants.GET_DETAIL_ITEM_REQUEST, constants.GET_DETAIL_ITEM_SUCCESS, constants.GET_DETAIL_ITEM_FAILURE
    )
}

function save(product) {
    return doAction(services.save(product),
        constants.SAVE_ITEM_REQUEST, constants.SAVE_ITEM_SUCCESS, constants.SAVE_ITEM_FAILURE
    )
}

function deleteItem(id) {
    return doAction(services.deleteItem(id),
        constants.DELETE_ITEM_REQUEST, constants.DELETE_ITEM_SUCCESS, constants.DELETE_ITEM_FAILURE
    )
}

function getMenuItemTypes() {
    return doAction(services.getMenuItemTypes(),
        constants.GET_MENU_ITEM_TYPES_REQUEST, constants.GET_MENU_ITEM_TYPES_SUCCESS, constants.GET_MENU_ITEM_TYPES_FAILURE
    )
}