import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { userActions } from '../actions';

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (       
        userActions.verify()
            ? <Component {...props} />
            : <Redirect to={{ pathname: "/login" }} />
    )} />
)
