import { paymentTypeConstants } from '../constants';

const initialState = {
    dataSource: [],
    isFetching: false,
    errors: {},
    forceReload: true,
    dataItem: null
}

export function paymentTypes(state = {}, action) {
    state = state || initialState;

    switch (action.type) {
        case paymentTypeConstants.GET_ALL_REQUEST:
            return {
                isFetching: true
            };

        case paymentTypeConstants.GET_ALL_SUCCESS:
            return {
                dataSource: action.payload,
                isFetching: false,
                forceReload: false
            };

        case paymentTypeConstants.GET_ALL_FAILURE:
            return {
                error: action.error,
                isFetching: false
            };

        /********************************************************/
        case paymentTypeConstants.GET_ITEM_REQUEST:
            return {
                ...state,
                isFetching: true
            };

        case paymentTypeConstants.GET_ITEM_SUCCESS:
            return {
                ...state,
                isFetching: false,
                dataItem: action.payload
            };

        case paymentTypeConstants.GET_ITEM_FAILURE:
            return {
                error: action.error,
                isFetching: false
            };

        /********************************************************/
        case paymentTypeConstants.SAVE_ITEM_REQUEST:
            return {
                isFetching: true
            };

        case paymentTypeConstants.SAVE_ITEM_SUCCESS:
            return {
                forceReload: true,
                dataItem: null
            };

        case paymentTypeConstants.SAVE_ITEM_FAILURE:
            return {
                error: action.error,
                isFetching: false
            };

        /********************************************************/

        case paymentTypeConstants.CANCEL_CHANGE:
            return {
                ...state,
                dataItem: null,
            };

        default:
            return state
    }
}