import { bookingConstants as constants } from '../constants';

const initialState = {
    storeProfile: {},
    dataSource:[],
    isFetching: false,
    errors: {},    
}

export function bookingReducer(state = {}, action) {
    state = state || initialState;
    
    switch (action.type) {
        /*******************************************************/
        case constants.SEARCH_AVAILABLE_TIME_SLOTS_REQUEST:
            return {
                ...state,
                isFetching: true,
                availableTimeSlots: null,
                responseResult: null,
            };

        case constants.SEARCH_AVAILABLE_TIME_SLOTS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                availableTimeSlots: action.payload,
                errors: {
                    status: true,
                    message: "OK"
                },
            };

        case constants.SEARCH_AVAILABLE_TIME_SLOTS_FAILURE:
            return {
                isFetching: false,
                errors: {
                    status: false,
                    message:action.error
                },
            };

    /*******************************************************/

        case constants.GET_STORE_PROFILE_REQUEST:
            return {
                ...state,
                isFetching: true
            };

        case constants.GET_STORE_PROFILE_SUCCESS:
            return {
                storeProfile: action.payload,
                isFetching: false,
            };

        case constants.GET_STORE_PROFILE_FAILURE:
            return {
                error: action.error,
                isFetching: false
            };

        /*******************************************************/

        case constants.SEND_VERIFY_CODE_REQUEST:
        case constants.GET_CUSTOMER_INFO_REQUEST:
            return {
                ...state,
                responseResult: null,
                isFetching: true
            };

        case constants.GET_CUSTOMER_INFO_SUCCESS:
            return {
                ...state,
                customerResponse: action.payload,
                isFetching: false,
            };

        case constants.SEND_VERIFY_CODE_SUCCESS:
            return {
                ...state,
                verifyCodeResult: action.payload,
                isFetching: false,
            };

        case constants.GET_CUSTOMER_INFO_FAILURE:
        case constants.SEND_VERIFY_CODE_FAILURE:
            return {
                error: action.error,
                isFetching: false
            };

    /*******************************************************/

        case constants.BOOK_APPOINTMENT_REQUEST:
            return {
                ...state,
                verifyCodeResult: null,
                isFetching: true
            };

        case constants.BOOK_APPOINTMENT_SUCCESS:
            return {
                ...state,
                responseResult: action.payload,
                isFetching: false,
            };

        case constants.BOOK_APPOINTMENT_FAILURE:
            return {
                error: action.error,
                isFetching: false
            };

    /*******************************************************/

        default:
            return state
    }
}