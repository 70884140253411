import { checkInConstants as constants } from '../constants';

const initialState = {
    isFetching: false,
    errors: {},
    appointments: [],
    checkInResult: {}
}

export function checkInReducer(state = {}, action) {
    state = state || initialState;

    switch (action.type) {
        case constants.MAKE_DATA_ALL_REQUEST:
        case constants.GET_APPTS_UP_COMING_REQUEST:
        case constants.CHECKIN_APPT_REQUEST:
            return {
                ...state,
                isFetching: true
            };

        case constants.MAKE_DATA_ALL_SUCCESS:
            return {
                dataSource: action.payload,
                isFetching: false,
                isForceReload: false
            };

        case constants.GET_APPTS_UP_COMING_SUCCESS:
            return {
                responseResult: action.payload,
                isFetching: false,
                isForceReload: false
            };

        case constants.CHECKIN_APPT_SUCCESS:
            return {
                ...state,
                checkInResult: action.payload,
                isFetching: false,
                isForceReload: false
            };

        case constants.MAKE_DATA_ALL_FAILURE:
        case constants.GET_APPTS_UP_COMING_FAILURE:
        case constants.CHECKIN_APPT_FAILURE:
            return {
                error: action.error,
                isFetching: false
            };

        /********************************************************/
        
        case constants.CANCEL_CHANGE:
            return {
                ...state,
                dataItem: null,
                responseResult: null
            };

        default:
            return state
    }
}