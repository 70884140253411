import * as serviceBase from './service.base';

const baseRoute = "api/customers";

export const customerService = {
    getAll,
    search,
    save,
    getDetail,
    deleteItem,
    getDataExcel,
    exportToExcel,
    importFromExcel,
};

async function importFromExcel(dataSource) {
    return await serviceBase.importFromExcel(`api/import/data-customers/`, dataSource);
}

async function getDataExcel(fileName) {
    return await serviceBase.getItem(`api/import/get-data-customers/${fileName}`);
}

async function exportToExcel() {
    return await serviceBase.exportToExcel(`api/import/export-customers/`);
}

async function getAll(page, pageSize, criteria) {
    if (criteria === null || criteria === undefined || criteria === "") {
        criteria = "null";
    }

    return await serviceBase.getCollection(`${baseRoute}/get-all/${page}/${pageSize}/${criteria}`);
}

async function search(criteria, onlyActive) {
    if (criteria === null || criteria === undefined || criteria === "") {
        criteria = "null";
    }

    return await serviceBase.getCollection(`${baseRoute}/search/${criteria}/${onlyActive}`);
}

async function getDetail(id) {
    return await serviceBase.getItem(`${baseRoute}/get-detail/${id}`);
}

async function deleteItem(id) {
    return await serviceBase.removeItem(`${baseRoute}/delete/${id}`);
}

async function save(customer) {
    return await serviceBase.saveItem(`${baseRoute}/save`, customer);
}
