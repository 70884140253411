import moment from 'moment';
import { bookingConstants } from '../constants';
import { bookingServices } from '../services';

export const bookingActions = {
    getSlotItems,
    getStoreProfile,
    searchAvailableTimeSlots,
    bookAppointment,
    findCustomerByPhone,
    findCustomerByEmail,
    sendVerifyCode
};

function bookAppointment(appointment) {
    return dispatch => {
        dispatch(request());

        bookingServices.bookAppointment(appointment)
            .then(
                data => {
                    dispatch(success(data));
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: bookingConstants.BOOK_APPOINTMENT_REQUEST } }
    function success(data) { return { type: bookingConstants.BOOK_APPOINTMENT_SUCCESS, payload: data } }
    function failure(error) { return { type: bookingConstants.BOOK_APPOINTMENT_FAILURE, error } }
}

function findCustomerByEmail(email) {
    return dispatch => {
        dispatch(request());

        bookingServices.findCustomerByEmail(email)
            .then(
                data => {
                    dispatch(success(data));
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: bookingConstants.GET_CUSTOMER_INFO_REQUEST } }
    function success(data) { return { type: bookingConstants.GET_CUSTOMER_INFO_SUCCESS, payload: data } }
    function failure(error) { return { type: bookingConstants.GET_CUSTOMER_INFO_FAILURE, error } }
}

function findCustomerByPhone(phone) {
    return dispatch => {
        dispatch(request());

        bookingServices.findCustomerByPhone(phone)
            .then(
                data => {
                    dispatch(success(data));
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: bookingConstants.GET_CUSTOMER_INFO_REQUEST } }
    function success(data) { return { type: bookingConstants.GET_CUSTOMER_INFO_SUCCESS, payload: data } }
    function failure(error) { return { type: bookingConstants.GET_CUSTOMER_INFO_FAILURE, error } }
}

function searchAvailableTimeSlots(appointment) {
    return dispatch => {
        dispatch(request());

        bookingServices.searchAvailableTimeSlots(appointment)
            .then(
                data => {
                    dispatch(success(data));
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: bookingConstants.SEARCH_AVAILABLE_TIME_SLOTS_REQUEST } }
    function success(data) { return { type: bookingConstants.SEARCH_AVAILABLE_TIME_SLOTS_SUCCESS, payload: data } }
    function failure(error) { return { type: bookingConstants.SEARCH_AVAILABLE_TIME_SLOTS_FAILURE, error } }
}

function sendVerifyCode(phone) {
    return dispatch => {
        dispatch(request());

        bookingServices.sendVerifyCode(phone)
            .then(
                data => {
                    dispatch(success(data));
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: bookingConstants.SEND_VERIFY_CODE_REQUEST } }
    function success(data) { return { type: bookingConstants.SEND_VERIFY_CODE_SUCCESS, payload: data } }
    function failure(error) { return { type: bookingConstants.SEND_VERIFY_CODE_FAILURE, error } }
}

function getStoreProfile(vanityUrl, referer) {
    return dispatch => {
        dispatch(request());

        bookingServices.getStoreProfile(vanityUrl, referer)
            .then(
                data => {
                    dispatch(success(data));
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: bookingConstants.GET_STORE_PROFILE_REQUEST } }
    function success(data) { return { type: bookingConstants.GET_STORE_PROFILE_SUCCESS, payload: data } }
    function failure(error) { return { type: bookingConstants.GET_STORE_PROFILE_FAILURE, payload: error } }
}

function getSlotItems(employeeId, bookDate) {
    return dispatch => {
        dispatch(request());

        let date = moment(bookDate).format("MM-DD-YYYY");
        bookingServices.getSlotItems(employeeId, date)
            .then(
                data => {
                    dispatch(success(data));
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: bookingConstants.GET_SLOT_ITEMS_REQUEST } }
    function success(data) { return { type: bookingConstants.GET_SLOT_ITEMS_SUCCESS, payload: data } }
    function failure(error) { return { type: bookingConstants.GET_SLOT_ITEMS_FAILURE, error } }
}