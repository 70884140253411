import * as serviceBase from './service.base';
const baseRoute = "api/checkin";

export const checkInServices = {
    makeMLData,
    checkIn,
    getApptUpcoming
};

async function getApptUpcoming(phone) {
    return await serviceBase.getCollection(`${baseRoute}/find-appointments-by-phone/${phone}`);
}

async function makeMLData() {
    return await serviceBase.getCollection(`${baseRoute}/make-ml-data`);
}

async function checkIn(appointment) {
    return await serviceBase.postDataObj(`${baseRoute}/check-in-appt`, appointment);
}
