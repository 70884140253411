export const checkInConstants = {
    gridId: "gridCategories",
    homeUrl: "/categories",
    addUrl: "/add-category",
    editUrl: "/edit-category/",

    MAKE_DATA_ALL_REQUEST: 'MAKE_DATA_ALL_REQUEST',
    MAKE_DATA_ALL_SUCCESS: 'MAKE_DATA_ALL_SUCCESS',
    MAKE_DATA_ALL_FAILURE: 'MAKE_DATA_ALL_FAILURE',

    PREDICT_DURATION_REQUEST: 'PREDICT_DURATION_REQUEST',
    PREDICT_DURATION_SUCCESS: 'PREDICT_DURATION_SUCCESS',
    PREDICT_DURATION_FAILURE: 'PREDICT_DURATION_FAILURE',

    GET_APPTS_UP_COMING_REQUEST: 'GET_APPTS_UP_COMING_REQUEST',
    GET_APPTS_UP_COMING_SUCCESS: 'GET_APPTS_UP_COMING_SUCCESS',
    GET_APPTS_UP_COMING_FAILURE: 'GET_APPTS_UP_COMING_FAILURE',

    CHECKIN_APPT_REQUEST: 'CHECKIN_APPT_REQUEST',
    CHECKIN_APPT_SUCCESS: 'CHECKIN_APPT_SUCCESS',
    CHECKIN_APPT_FAILURE: 'CHECKIN_APPT_FAILURE',

    CANCEL_CHANGE: 'CATEGORY_CANCEL_CHANGE'
};