export const categoryConstants = {
    gridId: "gridCategories",
    homeUrl: "/categories",
    addUrl: "/add-category",
    editUrl: "/edit-category/",

    GET_ALL_REQUEST: 'CATEGORY_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'CATEGORY_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'CATEGORY_GET_ALL_FAILURE',

    GET_DETAIL_ITEM_REQUEST: 'GET_DETAIL_CATEGORY_REQUEST',
    GET_DETAIL_ITEM_SUCCESS: 'GET_DETAIL_CATEGORY_SUCCESS',
    GET_DETAIL_ITEM_FAILURE: 'GET_DETAIL_CATEGORY_FAILURE',

    SAVE_ITEM_REQUEST: 'SAVE_CATEGORY_REQUEST',
    SAVE_ITEM_SUCCESS: 'SAVE_CATEGORY_SUCCESS',
    SAVE_ITEM_FAILURE: 'SAVE_CATEGORY_FAILURE',

    DELETE_ITEM_REQUEST: 'DELETE_CATEGORY_REQUEST',
    DELETE_ITEM_SUCCESS: 'DELETE_CATEGORY_SUCCESS',
    DELETE_ITEM_FAILURE: 'DELETE_CATEGORY_FAILURE',


    CANCEL_CHANGE: 'CATEGORY_CANCEL_CHANGE'
};