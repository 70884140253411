export const dashboardConstants = {
    GET_RETENTIONS_REQUEST: 'GET_RETENTIONS_REQUEST',
    GET_RETENTIONS_SUCCESS: 'GET_RETENTIONS_SUCCESS',
    GET_RETENTIONS_FAILURE: 'GET_RETENTIONS_FAILURE',

    GET_DASHBOARD_SUMMARY_REQUEST: 'GET_DASHBOARD_SUMMARY_REQUEST',
    GET_DASHBOARD_SUMMARY_SUCCESS: 'GET_DASHBOARD_SUMMARY_SUCCESS',
    GET_DASHBOARD_SUMMARY_FAILURE: 'GET_DASHBOARD_SUMMARY_FAILURE',

    GET_CUSTOMER_VISIT_REQUEST: 'GET_CUSTOMER_VISIT_REQUEST',
    GET_CUSTOMER_VISIT_SUCCESS: 'GET_CUSTOMER_VISIT_SUCCESS',
    GET_CUSTOMER_VISIT_FAILURE: 'GET_CUSTOMER_VISIT_FAILURE',

    GET_UPCOMING_APPOINMENTS_REQUEST: 'GET_UPCOMING_APPOINMENTS_REQUEST',
    GET_UPCOMING_APPOINMENTS_SUCCESS: 'GET_UPCOMING_APPOINMENTS_SUCCESS',
    GET_UPCOMING_APPOINMENTS_FAILURE: 'GET_UPCOMING_APPOINMENTS_FAILURE'
};
