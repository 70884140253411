import { customerConstants  as constants} from '../constants';
import { customerService as services } from '../services';
import { doAction } from './action.base';

export const customerActions = {
    getCustomers,
    search,
    searchForAppt,
    save,
    cancelChange,
    clearDataSaved,
    getDetail,
    addNew,
    deleteItem,
    getDataExcel,
    exportToExcel,
    importFromExcel,
};

function exportToExcel() {
    return doAction(services.exportToExcel(),
        constants.EXPORT_DATA_REQUEST, constants.EXPORT_DATA_SUCCESS, constants.EXPORT_DATA_FAILURE
    )
}

function importFromExcel(dataSource) {
    return doAction(services.importFromExcel(dataSource),
        constants.IMPORT_DATA_REQUEST, constants.IMPORT_DATA_SUCCESS, constants.IMPORT_DATA_FAILURE
    )
}

function getDataExcel(fileName) {
    return doAction(services.getDataExcel(fileName),
        constants.GET_DATA_EXCEL_REQUEST, constants.GET_DATA_EXCEL_SUCCESS, constants.GET_DATA_EXCEL_FAILURE
    )
}

function getCustomers(page, pageSize, criteria) {
    return doAction(services.getAll(page, pageSize, criteria),
        constants.GET_ALL_REQUEST, constants.GET_ALL_SUCCESS, constants.GET_ALL_FAILURE
    )
}

function search(criteria) {
    return doAction(services.search(criteria, false),
        constants.SEARCH_CUSTOMERS_REQUEST,
        constants.SEARCH_CUSTOMERS_SUCCESS,
        constants.SEARCH_CUSTOMERS_FAILURE
    );
}

function searchForAppt(criteria) {
    return doAction(services.search(criteria, true),
        constants.SEARCH_CUSTOMERS_FOR_APPT_REQUEST,
        constants.SEARCH_CUSTOMERS_FOR_APPT_SUCCESS,
        constants.SEARCH_CUSTOMERS_FOR_APPT_FAILURE
    );
}

function getDetail(id) {
    return doAction(services.getDetail(id),
        constants.GET_DETAIL_ITEM_REQUEST, constants.GET_DETAIL_ITEM_SUCCESS, constants.GET_DETAIL_ITEM_FAILURE
    )
}

function save(dataItem) {
    return doAction(services.save(dataItem),
        constants.SAVE_ITEM_REQUEST, constants.SAVE_ITEM_SUCCESS, constants.SAVE_ITEM_FAILURE
    )
}

function deleteItem(id) {
    return doAction(services.deleteItem(id),
        constants.DELETE_ITEM_REQUEST, constants.DELETE_ITEM_SUCCESS, constants.DELETE_ITEM_FAILURE
    )
}

function cancelChange() {
    return dispatch => dispatch({ type: constants.CANCEL_CHANGE });
}

function addNew() {
    return dispatch => dispatch({ type: constants.ADD_NEW_CUSTOMER_REQUEST });
}

function clearDataSaved() {
    return dispatch => dispatch({ type: constants.CLEAR_DATA_SAVED });
}

