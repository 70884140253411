export const runlogConstants = {
    gridId: "gridRunlogs",
    homeUrl: "",
    addUrl: "",
    editUrl: "",

    GET_ALL_REQUEST: 'RUNLOGS_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'RUNLOGS_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'RUNLOGS_GET_ALL_FAILURE',

};