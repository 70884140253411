//export const RetentionTypes = [
//    { value: 0, text: "New Customer" },
//    { value: 1, text: "Request" },
//    { value: 2, text: "Non Request" },
//    { value: 3, text: "Walk In" },
//    { value: 4, text: "Online" },
//];

export const RetentionTypes = [
    { value: "NewCustomer", text: "New Customer" },
    { value: "Request", text: "Request" },
    { value: "NonRequest", text: "Non Request" },
    { value: "WalkIn", text: "Walk In" },
    { value: "Online", text: "Online" },
];
