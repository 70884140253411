import { appointmentReducer } from './appointment.reducer';
import { authentication } from './authentication.reducer';
import { bookingReducer } from './booking.reducer';
import { category } from './category.reducer';
import { companyProfile } from './companyprofile.reducer';
import { customers } from './customer.reducer';
import { dashboardReducer } from './dashboard.reducer';
import { employeeReducer } from './employee.reducer';
import { jobcodes } from './jobcode.reducer';
import { paymentTypes } from './paymenttype.reducer';
import { products } from './product.reducer';
import { reportReducer } from './report.reducer';
import { runLogReducer } from './runlog.reducer';
import { voidreasons } from './voidreason.reducer';
import { checkInReducer } from './checkIn.reducer';
import { storeReducer } from './setStore.reducer';

export const reducers = {
    authentication,
    voidReasons: voidreasons,
    appointments: appointmentReducer,
    categories: category,
    products: products,
    jobCodes: jobcodes,
    paymentTypes,
    customers,
    companyProfile,
    employees: employeeReducer,
    runLogs: runLogReducer,
    reports: reportReducer,
    booking: bookingReducer,
    dashboard: dashboardReducer,
    checkIn: checkInReducer,
    stores: storeReducer
};

export default reducers;