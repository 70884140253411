import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as signalR from '@microsoft/signalr';
import { utils } from './core';
import { appointmentActions as actions, userActions } from './actions';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './App.scss';
import "@progress/kendo-theme-bootstrap/dist/all.scss";
import "bootstrap/scss/bootstrap.scss";
import { PrivateRoute } from './components';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const LoginWithToken = React.lazy(() => import('./views/Pages/Login/LoginWithToken'));

const TechAppLogin = React.lazy(() => import('./views/Pages/Login/TechAppLogin'));

const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));
const ApptBook = React.lazy(() => import('./views/Pages/ApptBook'));

class App extends Component {
    constructor(props) {
        super(props);

        this.initSignalR();
    }

    initSignalR = () => {
        try {
            const protocol = new signalR.JsonHubProtocol();
            const transport = signalR.HttpTransportType.WebSockets;

            const options = {
                transport,
                logMessageContent: true,
                logger: signalR.LogLevel.Information,
                //accessTokenFactory: () => accessToken,
            };

            // create the connection instance
            this.connection = new signalR.HubConnectionBuilder()
                .withUrl("/hubs/zotanotification", options)
                .configureLogging(signalR.LogLevel.Trace)
                .withAutomaticReconnect()
                .withHubProtocol(protocol)
                .build();

            // Note: to keep the connection open the serverTimeout should be
            // larger than the KeepAlive value that is set on the server
            // keepAliveIntervalInMilliseconds default is 15000 and we are using default
            // serverTimeoutInMilliseconds default is 30000 and we are using 60000 set below
            this.connection.serverTimeoutInMilliseconds = 60000;

            this.connection.start()
                .then(() => {
                    console.info('SignalR Connected');
                    console.log(this.connection.connectionId);

                    this.props.dispatch(userActions.updateConnectionId(this.connection.connectionId));
                })
                .then(() => this.connection.on("exportCompleted", (result) => {

                    if (!utils.isNullOrEmpty(result)) {
                        this.props.dispatch(actions.downloadFileExcel(result));
                    }
                }))
                .catch(err => console.error('SignalR Connection Error: ', err));
        }
        catch {

        }
    }

    render() {
        return (
            <HashRouter>
                <React.Suspense fallback={loading()}>
                    <Switch>
                        <Route exact path="/loginwithtoken/:token" name="Login Page" render={props => <LoginWithToken {...props} />} />
                        <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
                        <Route exact path="/edit-appointment/:apptId/:user/:pass/:istechappuser" name="Login Page" render={props => <TechAppLogin {...props} />} />
                        <Route exact path="/login/:user/:pass/:istechappuser" name="Login Page" render={props => <TechAppLogin {...props} />} />
                        <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
                        <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
                        <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
                        <Route path="/appt-book/:vanityName/:referer" render={props => <ApptBook {...props} />} />
                        <Route path="/appt-book/:vanityName" render={props => <ApptBook {...props} />} />

                        <PrivateRoute path="/" name="Home" component={DefaultLayout} />
                        <Route path="/" name="Home" component={DefaultLayout} />
                    </Switch>
                </React.Suspense>
            </HashRouter>
        );
    }
}

function mapStateToProps(state) {
    const { alert } = state;
    return {
        alert
    };
}
export default connect(mapStateToProps)(App)