export const jobCodeConstants = {
    gridId: "gridJobCode",
    homeUrl: "/manage/job-codes",
    addUrl: "/manage/add-jobcode/",
    editUrl: "/manage/edit-jobcode/",

    GET_ALL_REQUEST: 'JOBCODE_GETALL_REQUEST',
    GET_ALL_SUCCESS: 'JOBCODE_GETALL_SUCCESS',
    GET_ALL_FAILURE: 'JOBCODE_GETALL_FAILURE',

    GET_ITEM_REQUEST: 'JOBCODE_GET_ITEM_REQUEST',
    GET_ITEM_SUCCESS: 'JOBCODE_GET_ITEM_SUCCESS',
    GET_ITEM_FAILURE: 'JOBCODE_GET_ITEM_FAILURE',

    SAVE_ITEM_REQUEST: 'JOBCODE_SAVE_ITEM_REQUEST',
    SAVE_ITEM_SUCCESS: 'JOBCODE_SAVE_ITEM_SUCCESS',
    SAVE_ITEM_FAILURE: 'JOBCODE_SAVE_ITEM_FAILURE',

    CANCEL_CHANGE: 'CANCEL_CHANGE'
};