export const userConstants = {
    profileUrl: "/profile",

    LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
    LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
    LOGIN_FAILURE: "USERS_LOGIN_FAILURE",


    LOGOUT: 'USERS_LOGOUT',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    GET_PROFILE_REQUEST: 'GET_PROFILE_REQUEST',
    GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
    GET_PROFILE_FAILURE: 'GET_PROFILE_FAILURE',

    UPDATE_PROFILE_REQUEST: 'UPDATE_PROFILE_REQUEST',
    UPDATE_PROFILE_SUCCESS: 'UPDATE_PROFILE_SUCCESS',
    UPDATE_PROFILE_FAILURE: 'UPDATE_PROFILE_FAILURE',

    UPDATE_SIGNALR_CONNECTION_REQUEST: 'UPDATE_SIGNALR_CONNECTION_REQUEST',
    UPDATE_SIGNALR_CONNECTION_SUCCESS: 'UPDATE_SIGNALR_CONNECTION_SUCCESS',
    UPDATE_SIGNALR_CONNECTION_FAILURE: 'UPDATE_SIGNALR_CONNECTION_FAILURE',
};
