import { appointmentConstants, customerConstants as constants } from '../constants';

const initialState = {
    dataSource: [],
    customerSearchResult: {},
    isFetching: false,
    isForceReload: true,
    errors: {},    
    dataItem: null
}

export function customers(state = {}, action) {
    state = state || initialState;

    switch (action.type) {
        case appointmentConstants.SAVE_APPOINTMENTS_SUCCESS: 
        case appointmentConstants.UPDATE_APPOINTMENT_CONFIRM_STATUS_SUCCESS: 
        case appointmentConstants.UPDATE_APPOINTMENT_STATUS_SUCCESS: 
            return {
                ...state,
                dataItem: null
            };

        case constants.GET_ALL_REQUEST:
            return {
                isFetching: true
            };

        case constants.GET_ALL_SUCCESS:
            return {
                response: action.payload,
                isFetching: false,
                isForceReload: false
            };

        case constants.GET_ALL_FAILURE:
            return {
                error: action.error,
                isFetching: false
            };

    /********************************************************/

        case constants.SEARCH_CUSTOMERS_REQUEST:
            return {
                isFetching: true
            };

        case constants.SEARCH_CUSTOMERS_SUCCESS:
            return {
                response: action.payload,
                isFetching: false,
                isForceReload: false
            };

        case constants.SEARCH_CUSTOMERS_FAILURE:
            return {
                error: action.error,
                isFetching: false
            };

        /********************************************************/

        //case constants.SEARCH_CUSTOMERS_FOR_APPT_REQUEST:
        //    return {
        //        ...state,
        //        isFetching: true
        //    };

        case constants.SEARCH_CUSTOMERS_FOR_APPT_SUCCESS:
            return {
                customerSearchResult: action.payload,
                isFetching: false,
                isForceReload: false
            };

        case constants.SEARCH_CUSTOMERS_FOR_APPT_FAILURE:
            return {
                error: action.error,
                isFetching: false
            };

        /********************************************************/
        case constants.GET_DETAIL_ITEM_REQUEST:
            return {
                ...state,
                isFetching: true,
                dataItem: null
            };

        case constants.GET_DETAIL_ITEM_SUCCESS:
            return {
                ...state,
                isFetching: false,
                dataItem: action.payload
            };

        case constants.GET_DETAIL_ITEM_FAILURE:
            return {
                error: action.error,
                isFetching: false
            };

        /********************************************************/
        case constants.SAVE_ITEM_REQUEST:
            return {
                ...state,
                responseResult: null,
                isFetching: true
            };

        case constants.SAVE_ITEM_SUCCESS:
            return {
                ...state,
                response: null,
                isFetching: false,
                isForceReload: true,
                dataItem: null,
                responseResult: action.payload
            };

        case constants.SAVE_ITEM_FAILURE:
            return {
                error: action.error,
                isFetching: false
            };

        /********************************************************/
        case constants.DELETE_ITEM_REQUEST:
            return {
                ...state,
                isFetching: true
            };

        case constants.DELETE_ITEM_SUCCESS:
            return {
                ...state,
                isFetching: false,
                isForceReload: true,
                dataItem: null,
                responseResult: action.payload
            };

        case constants.DELETE_ITEM_FAILURE:
            return {
                errors: {
                    status: false,
                    message: "Error"
                },
                isFetching: false
            };

        /********************************************************/
        case constants.GET_DATA_EXCEL_REQUEST:
            return {
                ...state,
                isFetching: true
            };

        case constants.GET_DATA_EXCEL_SUCCESS:
            return {
                ...state,
                isFetching: false,
                excelDataSource: action.payload
            };

        case constants.GET_DATA_EXCEL_FAILURE:
            return {
                error: action.error,
                isFetching: false
            };

        /********************************************************/
        case constants.EXPORT_DATA_REQUEST:
            return {
                ...state,
                exportResult: null,
                isFetching: true
            };

        case constants.EXPORT_DATA_SUCCESS:
            return {
                ...state,
                isFetching: false,
                exportResult: action.payload
            };

        case constants.EXPORT_DATA_FAILURE:
            return {
                error: action.error,
                isFetching: false
            };

        /********************************************************/
        case constants.IMPORT_DATA_REQUEST:
            return {
                ...state,
                isFetching: true
            };

        case constants.IMPORT_DATA_SUCCESS:
            return {
                ...state,
                isFetching: false,
                isForceReload: true,
                importResult: action.payload
            };

        case constants.IMPORT_DATA_FAILURE:
            return {
                error: action.error,
                isFetching: false
            };

        /********************************************************/

        case constants.CANCEL_CHANGE:
            return {
                ...state,
                dataItem: null,
                responseResult: null
            };

        case constants.CLEAR_DATA_SAVED:
            return {
                ...state,
                dataItem: null,
                responseResult: null
            };

        case constants.ADD_NEW_CUSTOMER_REQUEST:
            return {
                ...state,
                dataItem: null,
                responseResult: null
            };

        default:
            return state
    }
}