import moment from 'moment';

import * as serviceBase from './service.base';

const baseRoute = "api/reports";

export const reportServices = {
    getByRetention,
    getByStatus,
    getOnlineAppointments,
    getPrebookAppointments,
    getAppointments,
    exportToExcel,
    exportToExcelByStatus,
    exportToExcelByRetention
};

async function getAppointments(start, end) {
    let s = moment(start).format("MM-DD-YYYY");
    let e = moment(end).format("MM-DD-YYYY");

    return await serviceBase.getCollection(`${baseRoute}/get-appointments/${s}/${e}/all`);
}

async function getPrebookAppointments(start, end) {
    let s = moment(start).format("MM-DD-YYYY");
    let e = moment(end).format("MM-DD-YYYY");

    return await serviceBase.getCollection(`${baseRoute}/get-appointments/${s}/${e}/prebook`);
}

async function getOnlineAppointments(start, end) {
    let s = moment(start).format("MM-DD-YYYY");
    let e = moment(end).format("MM-DD-YYYY");

    return await serviceBase.getCollection(`${baseRoute}/get-appointments/${s}/${e}/online`);
}

async function getByRetention(retentionType, start, end) {
    let s = moment(start).format("MM-DD-YYYY");
    let e = moment(end).format("MM-DD-YYYY");

    return await serviceBase.getCollection(`${baseRoute}/get-by-retention/${retentionType}/${s}/${e}`);
}

async function getByStatus(status, start, end) {
    let s = moment(start).format("MM-DD-YYYY");
    let e = moment(end).format("MM-DD-YYYY");

    return await serviceBase.getCollection(`${baseRoute}/get-by-status/${status}/${s}/${e}`);
}

async function exportToExcel(start, end, title, filterOption) {
    let s = moment(start).format("MM-DD-YYYY");
    let e = moment(end).format("MM-DD-YYYY");

    return await serviceBase.exportToExcel(`api/reports/export/all-appointments/${s}/${e}/${title}/${filterOption}`);
}

async function exportToExcelByStatus(start, end, title, status) {
    let s = moment(start).format("MM-DD-YYYY");
    let e = moment(end).format("MM-DD-YYYY");
    
    return await serviceBase.exportToExcel(`api/reports/export/by-status/${s}/${e}/${title}/${status}`);
}

async function exportToExcelByRetention(start, end, title, retention) {
    let s = moment(start).format("MM-DD-YYYY");
    let e = moment(end).format("MM-DD-YYYY");

    return await serviceBase.exportToExcel(`api/reports/export/by-retention/${s}/${e}/${title}/${retention}`);
}

