import { userConstants as constants } from '../constants';
import { userService as services } from '../services';
import { doAction } from './action.base';

export const userActions = {
    login,
    loginWithToken,
    logout,
    getAll,
    verify,
    getProfile,
    updateProfile,
    clearDataStorage,
    updateConnectionId
};

function clearDataStorage() {
    services.clearDataStorage();
}

function getProfile(username) {
    return doAction(services.getProfile(username),
        constants.GET_PROFILE_REQUEST, constants.GET_PROFILE_SUCCESS, constants.GET_PROFILE_FAILURE
    ) 
}

function updateConnectionId(connectionId) {
    return dispatch => {
        dispatch(request());
        dispatch(success(connectionId));
    };

    function request() { return { type: constants.UPDATE_SIGNALR_CONNECTION_REQUEST } }
    function success(payload) { return { type: constants.UPDATE_SIGNALR_CONNECTION_SUCCESS, payload } }

}

function updateProfile(userProfile) {
    return doAction(services.updateProfile(userProfile),
        constants.UPDATE_PROFILE_REQUEST, constants.UPDATE_PROFILE_SUCCESS, constants.UPDATE_PROFILE_FAILURE
    )
}

function verify() {
    return services.verify();
}

function login(username, password, isTechAppUser) {
    return doAction(services.login(username, password, isTechAppUser),
        constants.LOGIN_REQUEST, constants.LOGIN_SUCCESS, constants.LOGIN_FAILURE
    )
}

function loginWithToken(token) {
    return doAction(services.loginWithToken(token),
        constants.LOGIN_REQUEST, constants.LOGIN_SUCCESS, constants.LOGIN_FAILURE
    )
}


function logout() {
    services.logout();
    return { type: constants.LOGOUT };
}

function getAll() {
    return dispatch => {
        dispatch(request());

        services.getAll()
            .then(
                users => dispatch(success(users)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: constants.GETALL_REQUEST } }
    function success(users) { return { type: constants.GETALL_SUCCESS, users } }
    function failure(error) { return { type: constants.GETALL_FAILURE, error } }
}