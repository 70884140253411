import { checkInConstants as constants } from '../constants';
import { checkInServices as services } from '../services';
import { doAction } from './action.base';

export const checkInActions = {
    makeMLData,
    checkIn,
    getApptUpcoming
};

function getApptUpcoming(phone) {
    return doAction(services.getApptUpcoming(phone),
        constants.GET_APPTS_UP_COMING_REQUEST, constants.GET_APPTS_UP_COMING_SUCCESS, constants.GET_APPTS_UP_COMING_FAILURE
    )
}

function makeMLData() {
    return doAction(services.makeMLData(),
        constants.MAKE_DATA_ALL_REQUEST, constants.MAKE_DATA_ALL_SUCCESS, constants.MAKE_DATA_ALL_FAILURE
    )
}

function checkIn(appointment) {
    return doAction(services.checkIn(appointment),
        constants.CHECKIN_APPT_REQUEST, constants.CHECKIN_APPT_SUCCESS, constants.CHECKIN_APPT_FAILURE
    )
}



