import { runlogConstants } from '../constants';
import { runlogServices } from '../services';
import { doAction } from './action.base';


export const runlogActions = {
    getAll
};

function getAll(start, end) {
    return doAction(runlogServices.getAll(start, end),
        runlogConstants.GET_ALL_REQUEST,
        runlogConstants.GET_ALL_SUCCESS,
        runlogConstants.GET_ALL_FAILURE
    )
}