import { voidReasonConstants } from '../constants';
import { voidReasonService } from '../services';

export const voidReasonActions = {
    fetchData,
    fetchItem,
    saveItem,
    cancelChange
};

function fetchData() {
    return dispatch => {
        dispatch(request());

        voidReasonService.getAll()
            .then(
                data => {
                    dispatch(success(data));
                    console.log("fetchData_data");
                    console.log(data);
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: voidReasonConstants.GET_ALL_REQUEST } }
    function success(data) { return { type: voidReasonConstants.GET_ALL_SUCCESS, payload:data } }
    function failure(error) { return { type: voidReasonConstants.GET_ALL_FAILURE, error } }
}

function fetchItem(id) {
    return dispatch => {
        dispatch(request());

        voidReasonService.getItem(id)
            .then(
                data => {
                    dispatch(success(data));
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: voidReasonConstants.GET_ITEM_REQUEST } }
    function success(data) { return { type: voidReasonConstants.GET_ITEM_SUCCESS, payload:data } }
    function failure(error) { return { type: voidReasonConstants.GET_ITEM_FAILURE, error } }
}

function saveItem(dataItem) {
    return dispatch => {
        dispatch(request());
        console.log("dispatch(request())");
        voidReasonService.saveItem(dataItem)
            .then(
                data => {
                    dispatch(success(data));
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: voidReasonConstants.SAVE_ITEM_REQUEST } }
    function success(data) { return { type: voidReasonConstants.SAVE_ITEM_SUCCESS, payload: data } }
    function failure(error) { return { type: voidReasonConstants.SAVE_ITEM_FAILURE, error } }
}

function cancelChange(){
    return dispatch => dispatch({type: voidReasonConstants.CANCEL_CHANGE});
}