import { getCollection, getItem, removeItem, saveItem} from './service.base';
const baseRoute = "api/categories";

export const categoryServices = {
    getAll,
    getDetail,
    save,
    deleteItem
};

async function getAll() {
    return await getCollection(`${baseRoute}/get-all`);
}

async function getDetail(id) {
    return await getItem(`${baseRoute}/get-detail/${id}`);
}

async function save(category) {
    return await saveItem(`${baseRoute}/save`, category);
}

async function deleteItem(id) {
    return await removeItem(`${baseRoute}/delete/${id}`);
}
