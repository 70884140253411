const rootUrl = "api/appt-book";

export const bookingServices = {
    getSlotItems,
    getStoreProfile,
    searchAvailableTimeSlots,
    bookAppointment,
    findCustomerByPhone,
    findCustomerByEmail,
    sendVerifyCode
};

function authHeader() {
    let token = JSON.parse(localStorage.getItem('token'));

    if (token) {
        return {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        };
    } else {
        return {
            'Content-Type': 'application/json',
        };
    }
}

async function bookAppointment(appointment) {
    let url = `${rootUrl}/book-appointment`;
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(appointment)
    };

    let response = await fetch(url, requestOptions);
    let data = await response.json();

    return data;
}

async function findCustomerByEmail(email) {
    let url = `${rootUrl}/find-customer-byemail/${email}`;
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let response = await fetch(url, requestOptions);
    let data = await response.json();

    return data;
}

async function findCustomerByPhone(phone) {
    let url = `${rootUrl}/find-customer-byphone/${phone}`;
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let response = await fetch(url, requestOptions);
    let data = await response.json();

    return data;
}

async function searchAvailableTimeSlots(appointment) {
    let url = `${rootUrl}/search-time-slots`;
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(appointment)
    };

    let response = await fetch(url, requestOptions);
    let data = await response.json();

    return data;
}

async function getStoreProfile(vanityUrl, referer) {
    let url = `${rootUrl}/get-store-profile/${vanityUrl}/${referer}`;
    const requestOptions = {
        method: 'GET'
    };

    let response = await fetch(url, requestOptions);
    let data = await response.json();

    if (data && data.token) {
        localStorage.setItem('token', JSON.stringify(data.token));
    }

    return data;
}

async function sendVerifyCode(phone) {
    let url = `${rootUrl}/send-verify-code/${phone}`;
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    let response = await fetch(url, requestOptions);
    let data = await response.json();

    return data;
}

async function getSlotItems(employeeId, bookDate) {
    if (!employeeId || employeeId === "") {
        employeeId = "null";
    }
    
    let url = `api/Booking/GetSlotItems/${employeeId}/${bookDate}`;
    const requestOptions = {
        method: 'GET',
    };

    let response = await fetch(url, requestOptions);
    let data = await response.json();

    return data;
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}