import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history'
import { routerMiddleware, connectRouter  } from 'connected-react-router'
import { reducers } from '../reducers';

export const history = createBrowserHistory();

export default function configureStore(initialState = {}) {

    const middleware = [
        thunk,
        routerMiddleware(history)
    ];
    
    // In development, use the browser's Redux dev tools extension if installed
    const enhancers = [];
    const isDevelopment = process.env.NODE_ENV === 'development';
    if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
        enhancers.push(window.devToolsExtension());
    }    
   
    const rootReducer = (history) => combineReducers({
        ...reducers,
        router: connectRouter(history)
    })


    return createStore(
        rootReducer(history),
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );
}
