import { reportConstants as constants } from '../constants';

const initialState = {
    isFetching: false,
}

export function reportReducer(state = {}, action) {
    state = state || initialState;

    switch (action.type) {
        case constants.GET_BY_RETENTION_REQUEST:
        case constants.GET_BY_STATUS_REQUEST:
        case constants.GET_ONLINE_APPTS_REQUEST:
        case constants.GET_PREBOOK_APPTS_REQUEST: 
        case constants.GET_ALL_APPTS_REQUEST: 
        case constants.EXPORT_DATA_REQUEST:
            return {
                isFetching: true
            };

        case constants.GET_BY_RETENTION_SUCCESS:
        case constants.GET_BY_STATUS_SUCCESS:
        case constants.GET_ONLINE_APPTS_SUCCESS:
        case constants.GET_PREBOOK_APPTS_SUCCESS: 
        case constants.GET_ALL_APPTS_SUCCESS: 
            return {
                response: action.payload,
                isFetching: false,
            };

        case constants.GET_BY_RETENTION_FAILURE:
        case constants.GET_BY_STATUS_FAILURE:
        case constants.GET_ONLINE_APPTS_FAILURE:
        case constants.GET_PREBOOK_APPTS_FAILURE:
        case constants.GET_ALL_APPTS_FAILURE:
        case constants.EXPORT_DATA_FAILURE:
            return {
                error: action.error,
                isFetching: false
            };


        case constants.EXPORT_DATA_SUCCESS:
            return {
                exportResponse: action.payload,
                isFetching: false,
            };
        /********************************************************/

        default:
            return state
    }
}

/********************************************************/