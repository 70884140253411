import * as serviceBase from './service.base';

const baseRoute = "api/employees";

export const employeeService = {
    getAll,
    getDetail,
    save,
    deleteItem,
    getEmployeeQueueGroups,
    exportToExcel,
    importFromExcel,
    getDataExcel
};

async function importFromExcel(dataSource) {
    return await serviceBase.importFromExcel(`api/import/data-employees/`, dataSource);
}

async function getDataExcel(fileName) {
    return await serviceBase.getItem(`api/import/get-data-employees/${fileName}`);
}

async function exportToExcel() {
    return await serviceBase.exportToExcel(`api/import/export-employees/`);
}

async function getAll() {  
    return await serviceBase.getCollection(`${baseRoute}/get-all`);
}

async function getDetail(id) {
    return await serviceBase.getItem(`${baseRoute}/get-detail/${id}`);
}

async function save(employee) {
    serviceBase.clearCached();
    return await serviceBase.saveItem(`${baseRoute}/save`, employee);
}

async function deleteItem(id) {
    serviceBase.clearCached();
    return await serviceBase.removeItem(`${baseRoute}/delete/${id}`);
}

async function getEmployeeQueueGroups() {
    let queueGroups = serviceBase.getCache("queueGroups");

    if (queueGroups === null) {
        queueGroups = await serviceBase.getCollection(`${baseRoute}/get-employee-queue-groups`); 

        serviceBase.setCache("queueGroups", queueGroups);
    }

    return queueGroups;
}