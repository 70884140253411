export const customerConstants = {
    gridId: "gridCustomers",
    homeUrl: "/customers",
    addUrl: "/add-customer/",
    editUrl: "/edit-customer/",

    GET_ALL_REQUEST: 'CUSTOMER_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'CUSTOMER_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'CUSTOMER_GET_ALL_FAILURE',

    GET_DETAIL_ITEM_REQUEST: 'GET_DETAIL_CUSTOMER_REQUEST',
    GET_DETAIL_ITEM_SUCCESS: 'GET_DETAIL_CUSTOMER_SUCCESS',
    GET_DETAIL_ITEM_FAILURE: 'GET_DETAIL_CUSTOMER_FAILURE',

    SAVE_ITEM_REQUEST: 'SAVE_CUSTOMER_REQUEST',
    SAVE_ITEM_SUCCESS: 'SAVE_CUSTOMER_SUCCESS',
    SAVE_ITEM_FAILURE: 'SAVE_CUSTOMER_FAILURE',

    DELETE_ITEM_REQUEST: 'DELETE_CUSTOMER_REQUEST',
    DELETE_ITEM_SUCCESS: 'DELETE_CUSTOMER_SUCCESS',
    DELETE_ITEM_FAILURE: 'DELETE_CUSTOMER_FAILURE',

    SEARCH_CUSTOMERS_REQUEST: 'SEARCH_CUSTOMERS_REQUEST',
    SEARCH_CUSTOMERS_SUCCESS: 'SEARCH_CUSTOMERS_SUCCESS',
    SEARCH_CUSTOMERS_FAILURE: 'SEARCH_CUSTOMERS_FAILURE',   

    SEARCH_CUSTOMERS_FOR_APPT_REQUEST: 'SEARCH_CUSTOMERS_FOR_APPT_REQUEST',
    SEARCH_CUSTOMERS_FOR_APPT_SUCCESS: 'SEARCH_CUSTOMERS_FOR_APPT_SUCCESS',
    SEARCH_CUSTOMERS_FOR_APPT_FAILURE: 'SEARCH_CUSTOMERS_FOR_APPT_FAILURE',    

    ADD_NEW_CUSTOMER_REQUEST: 'ADD_NEW_CUSTOMER_REQUEST',  

    GET_DATA_EXCEL_REQUEST: 'GET_CUSTOMERS_DATA_EXCEL_REQUEST',
    GET_DATA_EXCEL_SUCCESS: 'GET_CUSTOMERS_DATA_EXCEL_SUCCESS',
    GET_DATA_EXCEL_FAILURE: 'GET_CUSTOMERS_DATA_EXCEL_FAILURE',

    EXPORT_DATA_REQUEST: 'EXPORT_DATA_CUSTOMERS_REQUEST',
    EXPORT_DATA_SUCCESS: 'EXPORT_DATA_CUSTOMERS_SUCCESS',
    EXPORT_DATA_FAILURE: 'EXPORT_DATA_CUSTOMERS_FAILURE',

    IMPORT_DATA_REQUEST: 'IMPORT_DATA_CUSTOMERS_REQUEST',
    IMPORT_DATA_SUCCESS: 'IMPORT_DATA_CUSTOMERS_SUCCESS',
    IMPORT_DATA_FAILURE: 'IMPORT_DATA_CUSTOMERS_FAILURE',

    CANCEL_CHANGE: 'CANCEL_CHANGE',
    CLEAR_DATA_SAVED: 'CLEAR_DATA_SAVED'
};