
export function authHeader() {
    let user = JSON.parse(localStorage.getItem('user'));

    if (user && user.token) {
        return {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.token
        };
    } else {
        return {};
    }
}

export function getRangeDateOfWeek (start) {
    var day = new Date(start).getDay();
    var date = start.getDate() - day;
    var startDate = new Date(new Date(start).setDate(date));
    var endDate = new Date(new Date(start).setDate(date + 6));

    return [startDate, endDate];
}

export function getRangeDateOfMonth(start) {
    var date = new Date(start);
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    return [firstDay, lastDay];
}

export function intToColor(i) {
    var hex = i.toString(16);
    var length = hex.length;
    if (length < 6) {
        for (length; length < 6; length++) {
            hex = "0" + hex;
        }
    }

    return "#" + hex;
}

export function isNull(obj) {
    return (obj === null || obj === undefined);
}

export function isNotNull(obj) {
    return (obj !== null && obj !== undefined);
}

//export function newGuid() {
//    let guid = (S4() + S4() + "-" + S4() + "-4" + S4().substr(0, 3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();
//    return guid;
//}

//function S4() {
//    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
//}

