import * as serviceBase from './service.base';

const baseRoute = "api/products";

export const productServices = {
    getAll,
    getAllMenuItems,
    getMenuItemTypes,
    getDetail,
    save,
    deleteItem,
    getDataExcel,
    importFromExcel,
    exportToExcel  
        
};

async function importFromExcel(dataSource) {
    return await serviceBase.importFromExcel(`api/import/data-products/`, dataSource);
}

async function getDataExcel(fileName) {
    return await serviceBase.getItem(`api/import/get-data-products/${fileName}`);
}

async function exportToExcel() {
    return await serviceBase.exportToExcel(`api/import/export-products/`);
}

async function getAll() {
    return await serviceBase.getCollection(`${baseRoute}/get-all`);
}

async function getAllMenuItems() {
    let menuItems = serviceBase.getCache("menuItems");

    if (menuItems === null) {
        menuItems = await serviceBase.getCollection(`${baseRoute}/get-all-menu-items`);

        serviceBase.setCache("menuItems", menuItems);
    }        

    return menuItems
}

async function getDetail(id) {
    return await serviceBase.getItem(`${baseRoute}/get-detail/${id}`);
}

async function save(product) {
    return await serviceBase.saveItem(`${baseRoute}/save`, product);
}

async function deleteItem(id) {
    return await serviceBase.removeItem(`${baseRoute}/delete/${id}`);
}

async function getMenuItemTypes() {
    let menuItemTypes = [
        { value: "Service", text: "Menu Item" },
        { value: "ServicePackage", text: "Service Package" },
    ]

    //menuItemTypes = await serviceBase.getCollection(`${baseRoute}/get-menu-item-types`);

    return menuItemTypes;
}