
export function actionSuccess(dataObj, actionName) {
    return dispatch => {
        dispatch(success(dataObj));
    };

    function success(data) { return { type: actionName, payload: data } }
}

export function doAction(serviceFunction, requestName, successName, failureName) {
    return dispatch => {
        dispatch(request());

        serviceFunction.then(data => {
            dispatch(success(data));
            },
            error => dispatch(failure(error))
        );
    };

    function request() { return { type: requestName } }
    function success(data) { return { type: successName, payload: data } }
    function failure(error) { return { type: failureName, error } }
}