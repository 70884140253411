export const userRoles = {
    Administrator: "Administrator",
    TechSupport: "TechSupport",
    CorporateManager: "CorporateManager",
    RegionalManager: "RegionalManager",
    POSUser: "POSUser",
    StoreManager: "StoreManager",
    CheckInUser: "CheckInUser",
    AccountSupportSupervisor: "AccountSupportSupervisor",
    AccountManager: "AccountManager",
    POSUser: "POSUser",
    ISOUser: "ISOUser"
}

export const settings = {
    pageSize: 50,
    formatDateTime: "MMMM DD, YYYY hh:mm a",
    cssLabelDefault: "col-sm-12 col-md-3 col-lg-3 col-xl-3",
    cssFieldDefault: "col-sm-12 col-md-9 col-lg-9 col-xl-6",
    cssFirstButtonDefault: "col-xs-12 col-sm-12 offset-md-3 col-md-3 offset-lg-3 col-lg-3 offset-xl-2 col-xl-2",
    cssFormButtonDefault: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-2"
}

export const reportColumns = [
    { field: 'appointmentDate', title: 'Date', format: '{0:MM/dd/yyyy}', width: 120, minWidth: 120 },
    { field: 'customerName', title: 'Customer', width: 200, minWidth: 200 },
    { field: 'phone', title: 'Phone', width: 150, minWidth: 150 },
    { field: 'serviceName', title: 'Service', minWidth: 200 },
    { field: 'startTime', title: 'Start Time', width: 120, minWidth: 120 },
    { field: 'endTime', title: 'End Time', width: 120, minWidth: 120 },
    { field: 'employeeName', title: 'Employee', width: 200, minWidth: 200 },
    { field: 'bookByUser', title: 'Book By', width: 150, minWidth: 150 }
];

export const defaultColor = {
    ForeInService: "#000000",
    ForeCheckOut: "#ffffff",
    ForeBlock: "#000000",
    ForeOnline: "#ffffff",
    ForeReturnRequest: "#ffffff",
    ForeHeldOnBook: "#ffffff",
    ForeNewCustomer: "#ffffff",
    ForeNonRequest: "#ffffff",
    ForeWalkIn: "#ffffff",

    BackInService: "#adff2f",
    BackCheckOut: "#545252",
    BackBlock: "#ffff00",
    BackOnline: "#008000",
    BackReturnRequest: "#d22c2c",
    BackHeldOnBook: "#a52a2a",
    BackNewCustomer: "#ab47bc",
    BackNonRequest: "#ef5350",
    BackWalkIn: "#1e88e5",

    EmployeeBackColor: "#2A2B6D",
    EmployeeForeColor: "#FFFFFF",

    ProductBackColor: "#25628F",
    ProductForeColor: "#ffffff"
}

export const socialInfo = {
    fbAppId: "253490166065266",
    fbAppFields: "name,first_name,last_name,email,picture",
    googleSecret: "VkB_21sW9krDClGi8n_YL7-o",
    googleAppId: "441930943198-k2feqhnhj8bh9t3eiiqc2ei1avn2n5t7.apps.googleusercontent.com"
};
