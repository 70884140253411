export const appointmentConstants = {
    gridApptLogs: "gridApptLogs",

    homeUrl: "/appointments",
    addUrl: "/add-appointment",
    addNewUrl: "/manage/add",
    editUrl: "/edit-appointment/",
    viewUrl: "/view-appointment/",
    apptLogsUrl: "/appointment-logs",

    GET_ALL_REQUEST: 'APPT_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'APPT_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'APPT_GET_ALL_FAILURE',

    GET_RETENTION_REQUEST: 'APPT_GET_RETENTION_REQUEST',
    GET_RETENTION_SUCCESS: 'APPT_GET_RETENTION_SUCCESS',
    GET_RETENTION_FAILURE: 'APPT_GET_RETENTION_FAILURE',

    GET_APPOINTMENTS_REQUEST: 'APPT_GET_APPOINTMENTS_REQUEST',
    GET_APPOINTMENTS_SUCCESS: 'APPT_GET_APPOINTMENTS_SUCCESS',
    GET_APPOINTMENTS_FAILURE: 'APPT_GET_APPOINTMENTS_FAILURE',

    GET_APPOINTMENT_RESOURCES_REQUEST: 'APPT_GET_APPOINTMENT_RESOURCES_REQUEST',
    GET_APPOINTMENT_RESOURCES_SUCCESS: 'APPT_GET_APPOINTMENT_RESOURCES_SUCCESS',
    GET_APPOINTMENT_RESOURCES_FAILURE: 'APPT_GET_APPOINTMENT_RESOURCES_FAILURE',

    GET_UNASSIGN_APPOINTMENTS_REQUEST: 'APPT_GET_UNASSIGN_APPOINTMENTS_REQUEST',
    GET_UNASSIGN_APPOINTMENTS_SUCCESS: 'APPT_GET_UNASSIGN_APPOINTMENTS_SUCCESS',
    GET_UNASSIGN_APPOINTMENTS_FAILURE: 'APPT_GET_UNASSIGN_APPOINTMENTS_FAILURE',

    GET_CANCEL_NOSHOW_APPOINTMENTS_REQUEST: 'APPT_CANCEL_NOSHOW_APPOINTMENTS_REQUEST',
    GET_CANCEL_NOSHOW_APPOINTMENTS_SUCCESS: 'APPT_CANCEL_NOSHOW_APPOINTMENTS_SUCCESS',
    GET_CANCEL_NOSHOW_APPOINTMENTS_FAILURE: 'APPT_CANCEL_NOSHOW_APPOINTMENTS_FAILURE',

    GET_ITEM_APPOINTMENT_REQUEST: 'APPT_GET_ITEM_APPOINTMENT_REQUEST',
    GET_ITEM_APPOINTMENT_SUCCESS: 'APPT_GET_ITEM_APPOINTMENT_SUCCESS',
    GET_ITEM_APPOINTMENT_FAILURE: 'APPT_GET_ITEM_APPOINTMENT_FAILURE',

    SAVE_APPOINTMENTS_REQUEST: 'APPT_SAVE_APPOINTMENTS_REQUEST',
    SAVE_APPOINTMENTS_SUCCESS: 'APPT_SAVE_APPOINTMENTS_SUCCESS',
    SAVE_APPOINTMENTS_FAILURE: 'APPT_SAVE_APPOINTMENTS_FAILURE',

    SAVE_BLOCKED_TIME_REQUEST: 'APPT_SAVE_BLOCKED_TIME_REQUEST',
    SAVE_BLOCKED_TIME_SUCCESS: 'APPT_SAVE_BLOCKED_TIME_SUCCESS',
    SAVE_BLOCKED_TIME_FAILURE: 'APPT_SAVE_BLOCKED_TIME_FAILURE',

    CHECK_BLOCKED_TIME_CONFLICT_REQUEST: 'APPT_CHECK_BLOCKED_TIME_CONFLICT_REQUEST',
    CHECK_BLOCKED_TIME_CONFLICT_SUCCESS: 'APPT_CHECK_BLOCKED_TIME_CONFLICT_SUCCESS',
    CHECK_BLOCKED_TIME_CONFLICT_FAILURE: 'APPT_CHECK_BLOCKED_TIME_CONFLICT_FAILURE',

    CHECK_CONFLICT_APPOINTMENTS_REQUEST: 'APPT_CONFLICT_APPOINTMENTS_REQUEST',
    CHECK_CONFLICT_APPOINTMENTS_SUCCESS: 'APPT_CONFLICT_APPOINTMENTS_SUCCESS',
    CHECK_CONFLICT_APPOINTMENTS_FAILURE: 'APPT_CONFLICT_APPOINTMENTS_FAILURE',

    UPDATE_APPOINTMENT_STATUS_REQUEST: 'APPT_UPDATE_APPOINTMENT_STATUS_REQUEST',
    UPDATE_APPOINTMENT_STATUS_SUCCESS: 'APPT_UPDATE_APPOINTMENT_STATUS_SUCCESS',
    UPDATE_APPOINTMENT_STATUS_FAILURE: 'APPT_UPDATE_APPOINTMENT_STATUS_FAILURE',

    UPDATE_APPOINTMENT_CONFIRM_STATUS_REQUEST: 'APPT_UPDATE_APPOINTMENT_CONFIRM_STATUS_REQUEST',
    UPDATE_APPOINTMENT_CONFIRM_STATUS_SUCCESS: 'APPT_UPDATE_APPOINTMENT_CONFIRM_STATUS_SUCCESS',
    UPDATE_APPOINTMENT_CONFIRM_STATUS_FAILURE: 'APPT_UPDATE_APPOINTMENT_CONFIRM_STATUS_FAILURE',

    GET_APPOINTMENTS_BY_CUSTOMER_REQUEST: 'GET_APPOINTMENTS_BY_CUSTOMER_REQUEST',
    GET_APPOINTMENTS_BY_CUSTOMER_SUCCESS: 'GET_APPOINTMENTS_BY_CUSTOMER_SUCCESS',
    GET_APPOINTMENTS_BY_CUSTOMER_FAILURE: 'GET_APPOINTMENTS_BY_CUSTOMER_FAILURE',

    DRAG_UPDATE_APPOINTMENT_REQUEST: 'APPT_DRAG_UPDATE_APPOINTMENT_REQUEST',
    DRAG_UPDATE_APPOINTMENT_SUCCESS: 'APPT_DRAG_UPDATE_APPOINTMENT_SUCCESS',
    DRAG_UPDATE_APPOINTMENT_FAILURE: 'APPT_DRAG_UPDATE_APPOINTMENT_FAILURE',

    DELETE_APPOINTMENT_REQUEST: 'APPT_DELETE_APPOINTMENT_REQUEST',
    DELETE_APPOINTMENT_SUCCESS: 'APPT_DELETE_APPOINTMENT_SUCCESS',
    DELETE_APPOINTMENT_FAILURE: 'APPT_DELETE_APPOINTMENT_FAILURE',

    ASSIGN_APPOINTMENT_REQUEST: 'APPT_ASSIGN_APPOINTMENT_REQUEST',
    ASSIGN_APPOINTMENT_SUCCESS: 'APPT_ASSIGN_APPOINTMENT_SUCCESS',
    ASSIGN_APPOINTMENT_FAILURE: 'APPT_ASSIGN_APPOINTMENT_FAILURE',

    CANCEL_CHANGE: 'CANCEL_CHANGE',
    CLEAR_APPOINTMENT_HISTORY: 'CLEAR_APPOINTMENT_HISTORY',

    GET_APPTLOGS_REQUEST: 'APPTLOGS_GET_REQUEST',
    GET_APPTLOGS_SUCCESS: 'APPTLOGS_GET_SUCCESS',
    GET_APPTLOGS_FAILURE: 'APPTLOGS_GET_FAILURE',

    CONFIRM_ONLINE_APPTS_REQUEST: 'CONFIRM_ONLINE_APPTS_REQUEST',
    CONFIRM_ONLINE_APPTS_SUCCESS: 'CONFIRM_ONLINE_APPTS_SUCCESS',
    CONFIRM_ONLINE_APPTS_FAILURE: 'CONFIRM_ONLINE_APPTS_FAILURE',

    COPY_APPOINTMENT_REQUEST: 'APPT_COPY_APPOINTMENT_REQUEST',
    COPY_APPOINTMENT_SUCCESS: 'APPT_COPY_APPOINTMENT_SUCCESS',
    COPY_APPOINTMENT_FAILURE: 'APPT_COPY_APPOINTMENT_FAILURE',

    MOVE_APPOINTMENT_REQUEST: 'APPT_MOVE_APPOINTMENT_REQUEST',
    MOVE_APPOINTMENT_SUCCESS: 'APPT_MOVE_APPOINTMENT_SUCCESS',
    MOVE_APPOINTMENT_FAILURE: 'APPT_MOVE_APPOINTMENT_FAILURE',

    GET_DATA_EXCEL_REQUEST: 'GET_APPOINTMENTS_DATA_EXCEL_REQUEST',
    GET_DATA_EXCEL_SUCCESS: 'GET_APPOINTMENTS_DATA_EXCEL_SUCCESS',
    GET_DATA_EXCEL_FAILURE: 'GET_APPOINTMENTS_DATA_EXCEL_FAILURE',

    EXPORT_DATA_REQUEST: 'EXPORT_DATA_APPOINTMENTS_REQUEST',
    EXPORT_DATA_SUCCESS: 'EXPORT_DATA_APPOINTMENTS_SUCCESS',
    EXPORT_DATA_FAILURE: 'EXPORT_DATA_APPOINTMENTS_FAILURE',

    IMPORT_DATA_REQUEST: 'IMPORT_DATA_APPOINTMENTS_REQUEST',
    IMPORT_DATA_SUCCESS: 'IMPORT_DATA_APPOINTMENTS_SUCCESS',
    IMPORT_DATA_FAILURE: 'IMPORT_DATA_APPOINTMENTS_FAILURE',

    GET_ONLINE_APPT_REQUEST: 'GET_ONLINE_APPT_REQUEST',
    GET_ONLINE_APPT_SUCCESS: 'GET_ONLINE_APPT_SUCCESS',
    GET_ONLINE_APPT_FAILURE: 'GET_ONLINE_APPT_FAILURE',

    GET_APPOINTMENTS_BY_DATE_REQUEST: 'GET_APPOINTMENTS_BY_DATE_REQUEST',
    GET_APPOINTMENTS_BY_DATE_SUCCESS: 'GET_APPOINTMENTS_BY_DATE_SUCCESS',
    GET_APPOINTMENTS_BY_DATE_FAILURE: 'GET_APPOINTMENTS_BY_DATE_FAILURE',

    REJECT_ONLINE_APPT_REQUEST: 'REJECT_ONLINE_APPT_REQUEST',
    REJECT_ONLINE_APPT_SUCCESS: 'REJECT_ONLINE_APPT_SUCCESS',
    REJECT_ONLINE_APPT_FAILURE: 'REJECT_ONLINE_APPT_FAILURE',
};
