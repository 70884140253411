export const paymentTypeConstants = {
    gridId: "gridPaymentType",
    homeUrl: "/manage/payment-types",
    addUrl: "/manage/payment-type",
    editUrl: "/manage/payment-type/",

    GET_ALL_REQUEST: 'PAYMENT_TYPE_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'PAYMENT_TYPE_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'PAYMENT_TYPE_GET_ALL_FAILURE',

    GET_ITEM_REQUEST: 'PAYMENT_TYPE_GET_ITEM_REQUEST',
    GET_ITEM_SUCCESS: 'PAYMENT_TYPE_GET_ITEM_SUCCESS',
    GET_ITEM_FAILURE: 'PAYMENT_TYPE_GET_ITEM_FAILURE',

    SAVE_ITEM_REQUEST: 'PAYMENT_TYPE_SAVE_ITEM_REQUEST',
    SAVE_ITEM_SUCCESS: 'PAYMENT_TYPE_SAVE_ITEM_SUCCESS',
    SAVE_ITEM_FAILURE: 'PAYMENT_TYPE_SAVE_ITEM_FAILURE',

    CANCEL_CHANGE: 'CANCEL_CHANGE'
};