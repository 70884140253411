export const voidReasonConstants = {
    gridId: "gridVoidReasons",
    homeUrl: "/manage/void-reasons",
    addUrl: "/manage/add-void-reason",
    editUrl: "/manage/edit-void-reason/",

    GET_ALL_REQUEST: 'VOIDREASONS_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'VOIDREASONS_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'VOIDREASONS_GET_ALL_FAILURE',

    GET_ITEM_REQUEST: 'VOIDREASONS_GET_ITEM_REQUEST',
    GET_ITEM_SUCCESS: 'VOIDREASONS_GET_ITEM_SUCCESS',
    GET_ITEM_FAILURE: 'VOIDREASONS_GET_ITEM_FAILURE',

    SAVE_ITEM_REQUEST: 'VOIDREASONS_SAVE_ITEM_REQUEST',
    SAVE_ITEM_SUCCESS: 'VOIDREASONS_SAVE_ITEM_SUCCESS',
    SAVE_ITEM_FAILURE: 'VOIDREASONS_SAVE_ITEM_FAILURE',

    CANCEL_CHANGE: 'CANCEL_CHANGE'
};