import { userConstants as constants } from '../constants';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { isAuthenticated: true, user } : {};

export function authentication(state = initialState, action) {
    switch (action.type) {
        case constants.LOGIN_REQUEST:        
            return {
                isFetching: true,
                user: action.user
            };

        case constants.LOGIN_SUCCESS:
            return {
                isAuthenticated: true,
                isFetching: false,
                user: action.user
            };

        case constants.LOGIN_FAILURE:
            return {
                isAuthenticated: false,
                isFetching: false,
                errors: action.error
            };

        case constants.LOGOUT:
            return {};

        /******************************************************************************/
        case constants.GET_PROFILE_REQUEST:
        case constants.UPDATE_SIGNALR_CONNECTION_REQUEST:
            return {
                ...state,
                isFetching: true,
                responseResult: null
            };

        case constants.UPDATE_SIGNALR_CONNECTION_SUCCESS:
            return {
                ...state,
                isFetching: false,
                connectionId: action.payload
            };

        case constants.GET_PROFILE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                profileInfo: action.payload
            };

        case constants.GET_PROFILE_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.error
            };

        /******************************************************************************/
        case constants.UPDATE_PROFILE_REQUEST:
            return {
                ...state,
                isFetching: true,
                responseResult: null
            };

        case constants.UPDATE_PROFILE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                responseResult: action.payload
            };

        case constants.UPDATE_PROFILE_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.error
            };

        default:
            return state
    }
}