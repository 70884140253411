import { productConstants as constants } from '../constants';

const initialState = {
    dataSource: [],
    menuItems: [],
    isFetching: false,
    errors: {},
    dataItem: null
}

export function products(state = {}, action) {
    state = state || initialState;

    switch (action.type) {
        case constants.GET_ALL_REQUEST:
            return {
                isFetching: true
            };

        case constants.GET_ALL_SUCCESS:
            return {
                ...state,
                dataSource: action.payload,
                isFetching: false,
                forceReload: false
            };

        case constants.GET_ALL_FAILURE:
            return {
                error: action.error,
                isFetching: false
            };

        /*******************************************************/
        case constants.GET_MENU_ITEMS_REQUEST:
            return {
                ...state,
                isFetching: true
            };

        case constants.GET_MENU_ITEMS_SUCCESS:
            
            return {
                ...state,
                isFetching: false,
                menuItems: action.payload
            };

        case constants.GET_MENU_ITEMS_FAILURE:
            return {
                isFetching: false,
                error: {
                    status: false,
                    message: action.payload
                },
            };

        /********************************************************/
        case constants.GET_DETAIL_ITEM_REQUEST:
            return {
                ...state,
                isFetching: true
            };

        case constants.GET_DETAIL_ITEM_SUCCESS:
            return {
                ...state,
                isFetching: false,
                dataItem: action.payload
            };

        case constants.GET_DETAIL_ITEM_FAILURE:
            return {
                error: action.error,
                isFetching: false
            };

        /********************************************************/
        case constants.SAVE_ITEM_REQUEST:
            return {
                ...state,
                isFetching: true
            };

        case constants.SAVE_ITEM_SUCCESS:
            return {
                ...state,
                isFetching: false,
                isForceReload: true,
                dataItem: null,
                responseResult: action.payload
            };

        case constants.SAVE_ITEM_FAILURE:
            return {
                errors: {
                    status: false,
                    message: "Error"
                },
                isFetching: false
            };

    /********************************************************/

        case constants.DELETE_ITEM_REQUEST:
            return {
                ...state,
                responseResult: null,
                isFetching: true
            };

        case constants.DELETE_ITEM_SUCCESS:
            return {
                ...state,
                isFetching: false,
                isForceReload: true,
                dataItem: null,
                responseResult: action.payload
            };

        case constants.DELETE_ITEM_FAILURE:
            return {
                errors: {
                    status: false,
                    message: "Error"
                },
                isFetching: false
            };

    /********************************************************/

        /********************************************************/
        case constants.GET_MENU_ITEM_TYPES_REQUEST:
            return {
                ...state,
                isFetching: true
            };

        case constants.GET_MENU_ITEM_TYPES_SUCCESS:
            return {
                ...state,
                isFetching: false,
                menuItemTypes: action.payload
            };

        case constants.GET_MENU_ITEM_TYPES_FAILURE:
            return {
                error: action.error,
                isFetching: false
            };

        /********************************************************/
        case constants.GET_DATA_EXCEL_REQUEST:
            return {
                ...state,
                isFetching: true
            };

        case constants.GET_DATA_EXCEL_SUCCESS:
            return {
                ...state,
                isFetching: false,
                excelDataSource: action.payload
            };

        case constants.GET_DATA_EXCEL_FAILURE:
            return {
                error: action.error,
                isFetching: false
            };

        /********************************************************/
        case constants.EXPORT_DATA_REQUEST:
            return {
                ...state,
                exportResult: null,
                isFetching: true
            };

        case constants.EXPORT_DATA_SUCCESS:
            return {
                ...state,
                isFetching: false,
                exportResult: action.payload
            };

        case constants.EXPORT_DATA_FAILURE:
            return {
                error: action.error,
                isFetching: false
            };

        /********************************************************/
        case constants.IMPORT_DATA_REQUEST:
            return {
                ...state,
                isFetching: true
            };

        case constants.IMPORT_DATA_SUCCESS:
            return {
                ...state,
                isFetching: false,
                isForceReload: true,
                importResult: action.payload
            };

        case constants.IMPORT_DATA_FAILURE:
            return {
                error: action.error,
                isFetching: false
            };

    /********************************************************/

        default:
            return state
    }
}