export const reportConstants = {
    gridId: "gridReports",
    homeUrl: "",
    addUrl: "",
    editUrl: "",

    GET_BY_RETENTION_REQUEST: 'REPORT_GET_BY_RETENTION_REQUEST',
    GET_BY_RETENTION_SUCCESS: 'REPORT_GET_BY_RETENTION_SUCCESS',
    GET_BY_RETENTION_FAILURE: 'REPORT_GET_BY_RETENTION_FAILURE',

    GET_BY_STATUS_REQUEST: 'REPORT_GET_BY_STATUS_REQUEST',
    GET_BY_STATUS_SUCCESS: 'REPORT_GET_BY_STATUS_SUCCESS',
    GET_BY_STATUS_FAILURE: 'REPORT_GET_BY_STATUS_FAILURE',

    GET_ONLINE_APPTS_REQUEST: 'REPORT_GET_ONLINE_APPTS_REQUEST',
    GET_ONLINE_APPTS_SUCCESS: 'REPORT_GET_ONLINE_APPTS_SUCCESS',
    GET_ONLINE_APPTS_FAILURE: 'REPORT_GET_ONLINE_APPTS_FAILURE',

    GET_PREBOOK_APPTS_REQUEST: 'REPORT_GET_PREBOOK_APPTS_REQUEST',
    GET_PREBOOK_APPTS_SUCCESS: 'REPORT_GET_PREBOOK_APPTS_SUCCESS',
    GET_PREBOOK_APPTS_FAILURE: 'REPORT_GET_PREBOOK_APPTS_FAILURE',

    GET_ALL_APPTS_REQUEST: 'REPORT_GET_ALL_APPTS_REQUEST',
    GET_ALL_APPTS_SUCCESS: 'REPORT_GET_ALL_APPTS_SUCCESS',
    GET_ALL_APPTS_FAILURE: 'REPORT_GET_ALL_APPTS_FAILURE',

    EXPORT_DATA_REQUEST: 'EXPORT_REPORT_APPOINTMENTS_REQUEST',
    EXPORT_DATA_SUCCESS: 'EXPORT_REPORT_APPOINTMENTS_SUCCESS',
    EXPORT_DATA_FAILURE: 'EXPORT_REPORT_APPOINTMENTS_FAILURE',

};