import { categoryConstants } from '../constants';

const initialState = {
    dataSource: [],
    isFetching: false,
    isForceReload: false,
    errors: {},
    dataItem: null
}

export function category(state = {}, action) {
    state = state || initialState;

    switch (action.type) {
        case categoryConstants.GET_ALL_REQUEST:
            return {
                isFetching: true
            };

        case categoryConstants.GET_ALL_SUCCESS:
            return {
                dataSource: action.payload,
                isFetching: false,
                isForceReload: false
            };

        case categoryConstants.GET_ALL_FAILURE:
            return {
                error: action.error,
                isFetching: false
            };

        /********************************************************/
        case categoryConstants.GET_DETAIL_ITEM_REQUEST:
            return {
                ...state,
                dataItem: null,
                responseResult: null,          
                isFetching: true
            };

        case categoryConstants.GET_DETAIL_ITEM_SUCCESS:
            return {
                ...state,
                isFetching: false,
                dataItem: action.payload
            };

        case categoryConstants.GET_DETAIL_ITEM_FAILURE:
            return {
                error: action.error,
                isFetching: false
            };

    /********************************************************/

        /********************************************************/
        case categoryConstants.SAVE_ITEM_REQUEST:
            return {
                ...state,
                isFetching: true
            };

        case categoryConstants.SAVE_ITEM_SUCCESS:
            return {
                ...state,
                isFetching: false,
                isForceReload: true,
                dataItem: null,
                responseResult: action.payload
            };

        case categoryConstants.SAVE_ITEM_FAILURE:
            return {
                errors: {
                    status: false,
                    message: "Error"
                },
                isFetching: false
            };

        /********************************************************/
        case categoryConstants.DELETE_ITEM_REQUEST:
            return {
                ...state,
                isFetching: true
            };

        case categoryConstants.DELETE_ITEM_SUCCESS:
            return {
                ...state,
                isFetching: false,
                isForceReload: true,
                dataItem: null,
                responseResult: action.payload
            };

        case categoryConstants.DELETE_ITEM_FAILURE:
            return {
                errors: {
                    status: false,
                    message: "Error"
                },
                isFetching: false
            };

        case categoryConstants.CANCEL_CHANGE:
            return {
                ...state,
                dataItem: null,
                responseResult: null
            };

        default:
            return state
    }
}