import { companyProfileConstants as constants } from '../constants';

const initialState = {
    dataSource: [],
    isFetching: false,
    businessHour: {},
    errors: {}
}

export function companyProfile(state = {}, action) {
    state = state || initialState;

    switch (action.type) {
        case constants.GET_ALL_REQUEST:
            return {
                isFetching: true
            };

        case constants.GET_ALL_SUCCESS:
            return {
                ...state,
                dataSource: action.payload,
                isFetching: false,
            };

        case constants.GET_ALL_FAILURE:
            return {
                isFetching: false,
                errors: {
                    status: false,
                    message: action.payload
                }
            };

        case constants.CHANGE_WORKDAY:
            return {
                ...state,
                businessHour: action.payload
            };

        /********************************************************/
        case constants.SAVE_ITEM_REQUEST:
            return {
                ...state,
                responseResult: null,
                isFetching: true
            };

        case constants.SAVE_ITEM_SUCCESS:
            return {
                ...state,
                responseResult: action.payload,
                isFetching: false,
            };

        case constants.SAVE_ITEM_FAILURE:
            return {
                error: action.error,
                isFetching: false
            };
        /********************************************************/
        case constants.GET_LANGUAGES_REQUEST:
            return {
                ...state,
            };

        case constants.GET_LANGUAGES_SUCCESS:
            return {
                ...state,
                languages: action.payload,
            };

        case constants.GET_LANGUAGES_FAILURE:
            return {
                error: action.error,
            };

        /********************************************************/
        case constants.GET_STATES_REQUEST:
            return {
                ...state,
            };

        case constants.GET_STATES_SUCCESS:
            return {
                ...state,
                states: action.payload,
            };

        case constants.GET_STATES_FAILURE:
            return {
                error: action.error,
            };

        /********************************************************/
        case constants.GET_STATES_REQUEST:
            return {
                ...state,
            };

        case constants.GET_STATES_SUCCESS:
            return {
                ...state,
                states: action.payload,
            };

        case constants.GET_STATES_FAILURE:
            return {
                error: action.error,
            };

        /********************************************************/
        case constants.GET_TIME_INTERVALS_REQUEST:
            return {
                ...state,
            };

        case constants.GET_TIME_INTERVALS_SUCCESS:
            return {
                ...state,
                timeIntervals: action.payload,
            };

        case constants.GET_TIME_INTERVALS_FAILURE:
            return {
                error: action.error,
            };
        /********************************************************/

        default:
            return state
    }
}