import moment from 'moment';
import * as serviceBase from './service.base';
const baseRoute = "api/appointments";

export const appointmentServices = {
    getRetentionTypes,
    getAppointments,
    getAppointment,
    saveAppointment,
    saveBlockedTime,
    updateAppointmentStatus,
    updateAppointmentConfirmStatus,
    //getAppointmentByCustomer,
    getApptLogs,
    dragUpdateAppointment,
    getUnassignAppointments,
    getCancelNoShowAppointments,
    checkConflictAppointment,
    assignAppointment,
    getResources,
    confirmOnlineAppts,
    copyAppointment,
    moveAppointment,
    deleteAppointment,
    exportToExcel,
    importFromExcel,
    getDataExcel,
    downloadFileExcel,
    getOnlineAppointments,
    getAppointmentsByDate,
    rejectOnlineAppt
};

async function importFromExcel(dataSource) {
    return await serviceBase.importFromExcel(`api/import/import-appointments/`, dataSource);
}

async function getDataExcel(fileName) {
    return await serviceBase.getItem(`api/import/get-data-appointments/${fileName}`);
}

async function exportToExcel(connectionId) {
    return await serviceBase.exportToExcel(`api/import/export-appointments/${connectionId}`);
}

async function downloadFileExcel(fileUrl) {
    return await serviceBase.exportToExcel(`api/import/download-file/${fileUrl}`);
}

async function copyAppointment(apptId, userId, timeSlot) {
    let time = moment(timeSlot).format("MM-DD-YYYY-hh-mm-A");

    return await serviceBase.saveItem(`${baseRoute}/copy-appointment/${apptId}/${userId}/${time}`);
}

async function moveAppointment(apptId, apptItemId, userId, timeSlot) {
    let time = moment(timeSlot).format("MM-DD-YYYY-hh-mm-A");

    return await serviceBase.saveItem(`${baseRoute}/move-appointment/${apptId}/${apptItemId}/${userId}/${time}`);
}

async function deleteAppointment(apptId) {
    return await serviceBase.saveItem(`${baseRoute}/delete-appointment/${apptId}/`);
}

async function assignAppointment(apptId, userId) {
    return await serviceBase.saveItem(`${baseRoute}/assign-appointment/${apptId}/${userId}`);
}

async function checkConflictAppointment(appointment) {
    return await serviceBase.validate(`${baseRoute}/check-conflict-appointments/`, appointment);
}

async function getUnassignAppointments(start, end) {
    let s = moment(start).format("MM-DD-YYYY");
    let e = moment(end).format("MM-DD-YYYY");

    return await serviceBase.getCollection(`${baseRoute}/get-unassign-appointments/${s}/${e}`);
}

async function getCancelNoShowAppointments(start, end) {
    let s = moment(start).format("MM-DD-YYYY");
    let e = moment(end).format("MM-DD-YYYY");

    return await serviceBase.getCollection(`${baseRoute}/get-cancel-noshow-appointments/${s}/${e}`);
}

async function dragUpdateAppointment(appointment) {
    return await serviceBase.saveItem(`${baseRoute}/update-appointment-service`, appointment);
}

//async function getAppointmentByCustomer(customerId) {
//    return await serviceBase.getCollection(`${baseRoute}/get-appointments-by-customer/${customerId}`);
//}

async function updateAppointmentConfirmStatus(appointment) {
    return await serviceBase.saveItem(`${baseRoute}/update-appointment-confirm-status`, appointment);
}

async function updateAppointmentStatus(appointment) {
    return await serviceBase.saveItem(`${baseRoute}/update-appointment-status`, appointment);
}

async function saveBlockedTime(appts) {
    return await serviceBase.saveItem(`${baseRoute}/save-blocktime`, appts);
}

async function saveAppointment(appointment) {
    return await serviceBase.saveItem(`${baseRoute}/save-appointment`, appointment);
}

async function getAppointment(id) {
    return await serviceBase.getItem(`${baseRoute}/get-detail/${id}`);
}

async function getAppointments(userId, start, end) {
    if (!userId || userId === "") {
        userId = "null";
    }

    let s = moment(start).format("MM-DD-YYYY");
    let e = moment(end).format("MM-DD-YYYY");

    return await serviceBase.getCollection(`${baseRoute}/get-appointments/${userId}/${s}/${e}`);
}

async function getResources() {
    //let employees = serviceBase.getCache("employees");

    //if (employees === null) {
    //    employees = await serviceBase.getCollection(`${baseRoute}/get-appointment-resources/`);
    //    serviceBase.setCache("employees", employees);
    //}

    return await serviceBase.getCollection(`${baseRoute}/get-appointment-resources/`);
}

async function getRetentionTypes() {
    //let retentionTypes = serviceBase.getCache("retentionTypes");

    //if (retentionTypes === null) {
    //    retentionTypes = await serviceBase.getCollection(`${baseRoute}/get-retention-types`);
    //    serviceBase.setCache("retentionTypes", retentionTypes);
    //}
    //let retentionTypes = [
    //    { value: 0, text: "New Customer" },
    //    { value: 1, text: "Request" },
    //    { value: 2, text: "Non Request" },
    //    { value: 3, text: "Walk In" },
    //    { value: 4, text: "Online" },
    //]
    let retentionTypes = [
        { value: "NewCustomer", text: "New Customer" },
        { value: "Request", text: "Request" },
        { value: "NonRequest", text: "Non Request" },
        { value: "WalkIn", text: "Walk In" },
        { value: "Online", text: "Online" },
    ]

        
    return retentionTypes
}

async function getApptLogs(date) {
    let d = moment(date).format("MM-DD-YYYY");

    return await serviceBase.getCollection(`${baseRoute}/get-appointments-logs/${d}`);
}

async function confirmOnlineAppts(apptIds) {
    return await serviceBase.saveItem(`${baseRoute}/confirm-online-appts`, apptIds);
}

async function getOnlineAppointments() {
    return await serviceBase.getCollection(`${baseRoute}/get-online-appointments`);
}

async function getAppointmentsByDate(date) {
    let d = moment(date).format("MM-DD-YYYY");

    return await serviceBase.getCollection(`${baseRoute}/get-appointments-bydate/${d}`);
}

async function rejectOnlineAppt(id) {
    return await serviceBase.saveItem(`${baseRoute}/reject-online-appointment/${id}/`);
}
