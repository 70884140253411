import { dashboardConstants as constants } from '../constants';

const initialState = {
    error: {},
    isFetching: false,
    retentions: null,
    dashboardSummary: null,
    upcomingAppointments: null,
    customerVisits: null
}

export function dashboardReducer(state = {}, action) {
    state = state || initialState;

    switch (action.type) {
        case constants.GET_RETENTIONS_REQUEST:
            return {
                ...state,
                isFetching: true
            };

        case constants.GET_RETENTIONS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                retentions: action.payload
            };

        case constants.GET_RETENTIONS_FAILURE:
            return {
                isFetching: false,
                error: action.error,
            };

        /*******************************************************/
        case constants.GET_DASHBOARD_SUMMARY_REQUEST:
            return {
                ...state,
                isFetching: true
            };

        case constants.GET_DASHBOARD_SUMMARY_SUCCESS:
            return {
                ...state,
                isFetching: false,
                dashboardSummary: action.payload
            };

        case constants.GET_DASHBOARD_SUMMARY_FAILURE:
            return {
                isFetching: false,
                error: action.error,
            };
        /*******************************************************/
        case constants.GET_CUSTOMER_VISIT_REQUEST:
            return {
                ...state,
                isFetching: true
            };

        case constants.GET_CUSTOMER_VISIT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                customerVisits: action.payload
            };

        case constants.GET_CUSTOMER_VISIT_FAILURE:
            return {
                isFetching: false,
                error: action.error,
            };
        /*******************************************************/
        case constants.GET_UPCOMING_APPOINMENTS_REQUEST:
            return {
                ...state,
                isFetching: true
            };

        case constants.GET_UPCOMING_APPOINMENTS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                upcomingAppointments: action.payload
            };

        case constants.GET_UPCOMING_APPOINMENTS_FAILURE:
            return {
                isFetching: false,
                error: action.error,
            };

        default:
            return state
    }
}