import { getCollection } from './service.base';
const baseRoute = "api/dashboard";

export const dashboardServices = {
    getRetiontions,
    getDashboardSummary,
    getCustomerVisits,
    getUpcomingAppointments
};

async function getRetiontions() {
    return await getCollection(`${baseRoute}/get-retiontions`);
}

async function getDashboardSummary() {
    return await getCollection(`${baseRoute}/get-dashboard-summary`);
}

async function getCustomerVisits() {
    return await getCollection(`${baseRoute}/get-customer-visits`);
}

async function getUpcomingAppointments() {
    return await getCollection(`${baseRoute}/get-upcoming-appointments`);
}
