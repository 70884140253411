import { runlogConstants } from '../constants';

const initialState = {
    dataSource: [],
    isFetching: false,
    response: null,
}

export function runLogReducer (state = {}, action) {
    state = state || initialState;

    switch (action.type) {
        case runlogConstants.GET_ALL_REQUEST:
            return {
                isFetching: true
            };

        case runlogConstants.GET_ALL_SUCCESS:
            return {
                response: action.payload,
                isFetching: false,
            };

        case runlogConstants.GET_ALL_FAILURE:
            return {
                error: action.error,
                isFetching: false
            };

        default:
            return state
    }
}

/********************************************************/