import { employeeConstants as constants } from '../constants';
import { employeeService as services } from '../services';
import { doAction } from './action.base';

export const employeeActions = {
    fetchData,
    getDetail,
    saveItem,
    cancelChange,
    deleteItem,
    getEmployeeQueueGroups,
    exportToExcel,
    importFromExcel,
    getDataExcel
};

function exportToExcel() {
    return doAction(services.exportToExcel(),
        constants.EXPORT_DATA_REQUEST, constants.EXPORT_DATA_SUCCESS, constants.EXPORT_DATA_FAILURE
    )
}

function importFromExcel(dataSource) {
    return doAction(services.importFromExcel(dataSource),
        constants.IMPORT_DATA_REQUEST, constants.IMPORT_DATA_SUCCESS, constants.IMPORT_DATA_FAILURE
    )
}

function getDataExcel(fileName) {
    return doAction(services.getDataExcel(fileName),
        constants.GET_DATA_EXCEL_REQUEST, constants.GET_DATA_EXCEL_SUCCESS, constants.GET_DATA_EXCEL_FAILURE
    )
}

function fetchData() {
    return doAction(services.getAll(),
        constants.GET_ALL_REQUEST, constants.GET_ALL_SUCCESS, constants.GET_ALL_FAILURE
    )
}

function getDetail(id) {
    return doAction(services.getDetail(id),
        constants.GET_DETAIL_ITEM_REQUEST, constants.GET_DETAIL_ITEM_SUCCESS, constants.GET_DETAIL_ITEM_FAILURE
    )
}

function saveItem(dataItem) {
    return doAction(services.save(dataItem),
        constants.SAVE_ITEM_REQUEST, constants.SAVE_ITEM_SUCCESS, constants.SAVE_ITEM_FAILURE
    )
}

function deleteItem(id) {
    return doAction(services.deleteItem(id),
        constants.DELETE_ITEM_REQUEST, constants.DELETE_ITEM_SUCCESS, constants.DELETE_ITEM_FAILURE
    )
}

function cancelChange() {
    return dispatch => dispatch({ type: constants.CANCEL_CHANGE });
}

function getEmployeeQueueGroups() {
    return doAction(services.getEmployeeQueueGroups(),
        constants.GET_QUEUE_GROUPS_REQUEST, constants.GET_QUEUE_GROUPS_SUCCESS, constants.GET_QUEUE_GROUPS_FAILURE
    )
}