import { utils } from '../core';
import { saveItem, getItem, clearCached } from './service.base';
const baseRoute = "api/users";

export const userService = {
    login,
    loginWithToken,
    logout,
    verify,
    getProfile,
    updateProfile,
    clearDataStorage
};

function verify() {
    let user = localStorage.getItem("user");
    let expire = localStorage.getItem("expire");
    let result = user && (new Date(expire) > new Date());
    
    return result
}

export function IsTechAppUser() {
    let isTechAppUser = localStorage.getItem("isTechAppUser");
    let verified = verify();

    return (isTechAppUser && verified);
}

export function verifyUserToken(userName) {
    let user = JSON.parse(localStorage.getItem("user"));
    let expire = localStorage.getItem("expire");
    let result = user && user.username === userName && (new Date(expire) > new Date());

    return result
}

async function getProfile(username) {
    return await getItem(`${baseRoute}/get-profile/${username}`);
}

async function updateProfile(userProfile) {
    return await saveItem(`${baseRoute}/update-profile/`, userProfile);
}

function login(username, password, isTechAppUser) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };
    
    return fetch(`${baseRoute}/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let expire = new Date();

            clearDataStorage();

            expire.setDate(expire.getDate() + 1);
            localStorage.setItem("expire", expire);
            localStorage.setItem('user', JSON.stringify(user));

            if (isTechAppUser === true) {
                localStorage.setItem("isTechAppUser", isTechAppUser);
            }

            return user;
        });
}

function loginWithToken(token) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    return fetch(`${baseRoute}/login-with-token/${token}`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let expire = new Date();

            clearDataStorage();

            expire.setDate(expire.getDate() + 1);
            localStorage.setItem("expire", expire);
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem("isTechAppUser", true);

            return user;
        });
}

function clearDataStorage() {
    localStorage.removeItem("companyProfile");
    localStorage.removeItem("dataStorage");
}

function logout() {
    localStorage.removeItem('user');
    localStorage.removeItem("expire");    
    localStorage.removeItem("companyProfile");
    localStorage.removeItem("dataStorage");
    localStorage.removeItem("isTechAppUser");
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                // eslint-disable-next-line no-restricted-globals
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}