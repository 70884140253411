import { reportConstants as constants } from '../constants';
import { reportServices as services } from '../services';
import { doAction } from './action.base';

export const reportActions = {
    getByRetention,
    getByStatus,
    getOnlineAppointments,
    getPrebookAppointments,
    getAppointments,
    exportToExcel,
    exportToExcelByStatus,
    exportToExcelByRetention
};

function getAppointments(start, end) {
    return doAction(services.getAppointments(start, end),
        constants.GET_ALL_APPTS_REQUEST, constants.GET_ALL_APPTS_SUCCESS, constants.GET_ALL_APPTS_FAILURE
    );
}

function getOnlineAppointments(start, end) {
    return doAction(services.getOnlineAppointments(start, end),
        constants.GET_ONLINE_APPTS_REQUEST, constants.GET_ONLINE_APPTS_SUCCESS, constants.GET_ONLINE_APPTS_FAILURE
    );
}

function getPrebookAppointments(start, end) {
    return doAction(services.getPrebookAppointments(start, end),
        constants.GET_PREBOOK_APPTS_REQUEST, constants.GET_PREBOOK_APPTS_SUCCESS, constants.GET_PREBOOK_APPTS_FAILURE
    );    
}

function getByRetention(retentionType, start, end) {
    return doAction(services.getByRetention(retentionType, start, end),
        constants.GET_BY_RETENTION_REQUEST, constants.GET_BY_RETENTION_SUCCESS, constants.GET_BY_RETENTION_FAILURE
    );
}

function getByStatus(status, start, end) {
    return doAction(services.getByStatus(status, start, end),
        constants.GET_BY_STATUS_REQUEST, constants.GET_BY_STATUS_SUCCESS, constants.GET_BY_STATUS_FAILURE
    );
}

function exportToExcel(start, end, title, filterOption) {
    return doAction(services.exportToExcel(start, end, title, filterOption),
        constants.EXPORT_DATA_REQUEST, constants.EXPORT_DATA_SUCCESS, constants.EXPORT_DATA_FAILURE
    );
}

function exportToExcelByStatus(start, end, title, status) {    
    return doAction(services.exportToExcelByStatus(start, end, title, status),
        constants.EXPORT_DATA_REQUEST, constants.EXPORT_DATA_SUCCESS, constants.EXPORT_DATA_FAILURE
    );
}

function exportToExcelByRetention(start, end, title, retention) {
    return doAction(services.exportToExcelByRetention(start, end, title, retention),
        constants.EXPORT_DATA_REQUEST, constants.EXPORT_DATA_SUCCESS, constants.EXPORT_DATA_FAILURE
    );
}