export const employeeConstants = {
    gridId: "gridEmployees",
    homeUrl: "/employees",
    addUrl: "/add-employee",
    editUrl: "/edit-employee/",

    GET_ALL_REQUEST: 'EMPLOYEES_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'EMPLOYEES_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'EMPLOYEES_GET_ALL_FAILURE',

    GET_QUEUE_GROUPS_REQUEST: 'EMPLOYEES_QUEUE_GROUPS_REQUEST',
    GET_QUEUE_GROUPS_SUCCESS: 'EMPLOYEES_QUEUE_GROUPS_SUCCESS',
    GET_QUEUE_GROUPS_FAILURE: 'EMPLOYEES_QUEUE_GROUPS_FAILURE',

    GET_DETAIL_ITEM_REQUEST: 'GET_DETAIL_EMPLOYEE_REQUEST',
    GET_DETAIL_ITEM_SUCCESS: 'GET_DETAIL_EMPLOYEE_SUCCESS',
    GET_DETAIL_ITEM_FAILURE: 'GET_DETAIL_EMPLOYEE_FAILURE',

    SAVE_ITEM_REQUEST: 'SAVE_EMPLOYEE_REQUEST',
    SAVE_ITEM_SUCCESS: 'SAVE_EMPLOYEE_SUCCESS',
    SAVE_ITEM_FAILURE: 'SAVE_EMPLOYEE_FAILURE',

    DELETE_ITEM_REQUEST: 'DELETE_EMPLOYEE_REQUEST',
    DELETE_ITEM_SUCCESS: 'DELETE_EMPLOYEE_SUCCESS',
    DELETE_ITEM_FAILURE: 'DELETE_EMPLOYEE_FAILURE',

    GET_DATA_EXCEL_REQUEST: 'GET_EMPLOYEES_DATA_EXCEL_REQUEST',
    GET_DATA_EXCEL_SUCCESS: 'GET_EMPLOYEES_DATA_EXCEL_SUCCESS',
    GET_DATA_EXCEL_FAILURE: 'GET_EMPLOYEES_DATA_EXCEL_FAILURE',

    EXPORT_DATA_REQUEST: 'EXPORT_DATA_EMPLOYEES_REQUEST',
    EXPORT_DATA_SUCCESS: 'EXPORT_DATA_EMPLOYEES_SUCCESS',
    EXPORT_DATA_FAILURE: 'EXPORT_DATA_EMPLOYEES_FAILURE',

    IMPORT_DATA_REQUEST: 'IMPORT_DATA_EMPLOYEES_REQUEST',
    IMPORT_DATA_SUCCESS: 'IMPORT_DATA_EMPLOYEES_SUCCESS',
    IMPORT_DATA_FAILURE: 'IMPORT_DATA_EMPLOYEES_FAILURE',

    CANCEL_CHANGE: 'CANCEL_CHANGE'
};