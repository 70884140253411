import { authHeader } from './common';

export const paymentTypeService = {
    getAll,
    getItem
};

async function getAll() {
    let url = `api/PaymentType/GetAll`;
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return await fetch(url, requestOptions).then(handleResponse);
}

async function getItem(id) {
    let url = `api/PaymentType/GetItem/${id}`;
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return await fetch(url, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}