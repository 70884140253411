import moment from 'moment';
import { companyProfileConstants as constants, DayOfWeek } from '../constants';
import { companyProfileService as services } from '../services';
import { doAction } from './action.base';
import { utils } from '../core';

export const companyProfileActions = {
    getCompanyProfile,
    getBusinessWorkHours,
    getOnlineWorkHours,
    getWorkHours,
    saveCompanyProfile,
    getStates,
    getLanguages,
    getTimeIntervals
};

function saveCompanyProfile(dataObj) {
    return doAction(services.saveCompanyProfile(dataObj),
        constants.SAVE_ITEM_REQUEST, constants.SAVE_ITEM_SUCCESS, constants.SAVE_ITEM_FAILURE
    )
}

function getBusinessWorkHours(apptDate, companyProfile) {
    let day = apptDate.getDay();
    let fromHour, toHour, isAvailable;    

    switch (day) {
        case DayOfWeek.Monday:
            {
                isAvailable = companyProfile.businessHour.isMon;
                fromHour = companyProfile.businessHour.monFromHour;
                toHour = companyProfile.businessHour.monToHour;
            }

            break;

        case DayOfWeek.Tuesday:
            {
                isAvailable = companyProfile.businessHour.isTue;
                fromHour = companyProfile.businessHour.tueFromHour;
                toHour = companyProfile.businessHour.tueToHour;
            }

            break;

        case DayOfWeek.Wednesday:
            {
                isAvailable = companyProfile.businessHour.isWed;
                fromHour = companyProfile.businessHour.wedFromHour;
                toHour = companyProfile.businessHour.wedToHour;
            }

            break;

        case DayOfWeek.Thursday:
            {
                isAvailable = companyProfile.businessHour.isThu;
                fromHour = companyProfile.businessHour.thuFromHour;
                toHour = companyProfile.businessHour.thuToHour;                
            }

            break;

        case DayOfWeek.Friday:
            {
                isAvailable = companyProfile.businessHour.isFri;
                fromHour = companyProfile.businessHour.friFromHour;
                toHour = companyProfile.businessHour.friToHour;
            }

            break;

        case DayOfWeek.Saturday:
            {
                isAvailable = companyProfile.businessHour.isSat;
                fromHour = companyProfile.businessHour.satFromHour;
                toHour = companyProfile.businessHour.satToHour;
            }

            break;

        case DayOfWeek.Sunday:
            {
                isAvailable = companyProfile.businessHour.isSun;
                fromHour = companyProfile.businessHour.sunFromHour;
                toHour = companyProfile.businessHour.sunToHour;
            }

            break;
    }

    let start = utils.convertToDateTime(fromHour);
    let end = utils.convertToDateTime(toHour);

    let from = new Date(apptDate.getFullYear(), apptDate.getMonth(), apptDate.getDate(), start.getHours(), start.getMinutes());
    let to = new Date(apptDate.getFullYear(), apptDate.getMonth(), apptDate.getDate(), end.getHours(), end.getMinutes());

    return [isAvailable, from, to];
}

function getOnlineWorkHours(apptDate, companyProfile) {
    let day = apptDate.getDay();
    let fromHour, toHour, isAvailable;

    switch (day) {
        case DayOfWeek.Monday:
            {
                isAvailable = companyProfile.onlineHour.isMon;
                fromHour = companyProfile.onlineHour.monFromHour;
                toHour = companyProfile.onlineHour.monToHour;
            }

            break;

        case DayOfWeek.Tuesday:
            {
                isAvailable = companyProfile.onlineHour.isTue;
                fromHour = companyProfile.onlineHour.tueFromHour;
                toHour = companyProfile.onlineHour.tueToHour;
            }

            break;

        case DayOfWeek.Wednesday:
            {
                isAvailable = companyProfile.onlineHour.isWed;
                fromHour = companyProfile.onlineHour.wedFromHour;
                toHour = companyProfile.onlineHour.wedToHour;
            }

            break;

        case DayOfWeek.Thursday:
            {
                isAvailable = companyProfile.onlineHour.isThu;
                fromHour = companyProfile.onlineHour.thuFromHour;
                toHour = companyProfile.onlineHour.thuToHour;
            }

            break;

        case DayOfWeek.Friday:
            {
                isAvailable = companyProfile.onlineHour.isFri;
                fromHour = companyProfile.onlineHour.friFromHour;
                toHour = companyProfile.onlineHour.friToHour;
            }

            break;

        case DayOfWeek.Saturday:
            {
                isAvailable = companyProfile.onlineHour.isSat;
                fromHour = companyProfile.onlineHour.satFromHour;
                toHour = companyProfile.onlineHour.satToHour;
            }

            break;

        case DayOfWeek.Sunday:
            {
                isAvailable = companyProfile.onlineHour.isSun;
                fromHour = companyProfile.onlineHour.sunFromHour;
                toHour = companyProfile.onlineHour.sunToHour;
            }

            break;
    }

    let start = utils.convertToDateTime(fromHour);
    let end = utils.convertToDateTime(toHour);

    let from = new Date(apptDate.getFullYear(), apptDate.getMonth(), apptDate.getDate(), start.getHours(), start.getMinutes());
    let to = new Date(apptDate.getFullYear(), apptDate.getMonth(), apptDate.getDate(), end.getHours(), end.getMinutes());

    return [isAvailable, from, to];
}

function getWorkHours(companyProfile, d) {
    let day = d.getDay();
    
    switch (day) {
        case 0: //sunday
            return {
                isAvailable: companyProfile.businessHour.isSun,
                workDayStart: moment(new Date(companyProfile.businessHour.sunFromHour)).format("HH:mm"),
                workDayEnd: moment(new Date(companyProfile.businessHour.sunToHour)).format("HH:mm")
            }

        case 1: //Monday
            return {
                isAvailable: companyProfile.businessHour.isMon,
                workDayStart: moment(new Date(companyProfile.businessHour.monFromHour)).format("HH:mm"),
                workDayEnd: moment(new Date(companyProfile.businessHour.monToHour)).format("HH:mm")
            }

        case 2: //Tuesday
            return {
                isAvailable: companyProfile.businessHour.isTue,
                workDayStart: moment(new Date(companyProfile.businessHour.tueFromHour)).format("HH:mm"),
                workDayEnd: moment(new Date(companyProfile.businessHour.tueToHour)).format("HH:mm")
            }

        case 3: //Wednesday
            return {
                isAvailable: companyProfile.businessHour.isWed,
                workDayStart: moment(new Date(companyProfile.businessHour.wedFromHour)).format("HH:mm"),
                workDayEnd: moment(new Date(companyProfile.businessHour.wedToHour)).format("HH:mm")
            }

        case 4: //Thursday
            return {
                isAvailable: companyProfile.businessHour.isThu,
                workDayStart: moment(new Date(companyProfile.businessHour.thuFromHour)).format("HH:mm"),
                workDayEnd: moment(new Date(companyProfile.businessHour.thuToHour)).format("HH:mm")
            }

        case 5: //Friday
            return {
                isAvailable: companyProfile.businessHour.isFri,
                workDayStart: moment(new Date(companyProfile.businessHour.friFromHour)).format("HH:mm"),
                workDayEnd: moment(new Date(companyProfile.businessHour.friToHour)).format("HH:mm")
            }

        case 6: //Saturday
            return {
                isAvailable: companyProfile.businessHour.isSat,
                workDayStart: moment(new Date(companyProfile.businessHour.satFromHour)).format("HH:mm"),
                workDayEnd: moment(new Date(companyProfile.businessHour.satToHour)).format("HH:mm")
            }
    }
}

function getCompanyProfile() {
    return doAction(services.getCompanyProfile(),
        constants.GET_ALL_REQUEST, constants.GET_ALL_SUCCESS, constants.GET_ALL_FAILURE
    )
}

function getStates() {
    return doAction(services.getStates(),
        constants.GET_STATES_REQUEST, constants.GET_STATES_SUCCESS, constants.GET_STATES_FAILURE
    )
}

function getLanguages() {
    return doAction(services.getLanguages(),
        constants.GET_LANGUAGES_REQUEST, constants.GET_LANGUAGES_SUCCESS, constants.GET_LANGUAGES_FAILURE
    )
}

function getTimeIntervals() {
    return doAction(services.getTimeIntervals(),
        constants.GET_TIME_INTERVALS_REQUEST, constants.GET_TIME_INTERVALS_SUCCESS, constants.GET_TIME_INTERVALS_FAILURE
    )
}