import * as serviceBase from './service.base';
const baseRoute = "api/set-store";

export const setStoreServices = {
    getAll,
    selectStore,
    setStoreToken,
    save,
};


async function getAll() {
    return await serviceBase.getCollection(`${baseRoute}/get-all`);
}

async function selectStore(storeId) {
    return await serviceBase.getItem(`${baseRoute}/select-store/${storeId}`);
}

function setStoreToken(token) {
    localStorage.removeItem('user');
    localStorage.removeItem("expire");
    localStorage.removeItem("companyProfile");
    localStorage.removeItem("dataStorage");
    
    let expire = new Date();

    expire.setDate(expire.getDate() + 1);
    localStorage.setItem("expire", expire);
    localStorage.setItem('user', JSON.stringify(token));
}

async function save(obj) {
    return await serviceBase.saveItem(`${baseRoute}/save`, obj);
}
