import moment from 'moment';
import { userRoles } from './settings';;

export const utils = {
    convertToDateTime,
    parseDateTime,
    getOS,
    intToColor,
    colorToInt,
    rgbToHex,
    rgbaToHex,
    getUserRoles,    
    isNullOrEmpty,
    formatDate,
    formatDateTime,
    formatTime,
    isDesktop,
    isNotNull,
    viewPhoneNumber,
    viewEmail,
    getFunctionAccess,
    S4
}

export function formatCurrency(value) {
    let result = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);

    if (value < 0) {
        result = `(${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value * (-1))})`;
    }

    return result;
}

function viewPhoneNumber(phone) {
    let isViewPhoneEmail = getFunctionAccess().isViewPhoneEmail;
    if (!utils.isNullOrEmpty(phone) && phone.length >= 10 && !isViewPhoneEmail) {
        phone = `(***) ***-${phone.substring(10)}`;
    }

    return phone;
}

function viewEmail(email) {
    let isViewPhoneEmail = getFunctionAccess().isViewPhoneEmail;

    if (!utils.isNullOrEmpty(email) && email.indexOf("@") > -1 && !isViewPhoneEmail) {
        email = `********${email.substring(email.indexOf("@"))}`;
    }

    return email;
}

export function functionAccess() {
    let result = {};

    let loggedInRoles = getUserRoles();
    let f = getFunctionAccess();

    if (f !== null && f !== undefined) {
        result = f;
    }

    result.isAdminOrTechSupport = (loggedInRoles.indexOf(userRoles.Administrator) !== -1 || loggedInRoles.indexOf(userRoles.TechSupport) !== -1);

    return result;
}

export function IsStoreManager() {
    let result = false;

    let loggedInRoles = getUserRoles();

    result = (loggedInRoles.indexOf(userRoles.StoreManager) !== -1);

    return result;
}

export function IsRegionalManager() {
    let result = false;

    let loggedInRoles = getUserRoles();

    result = (loggedInRoles.indexOf(userRoles.RegionalManager) !== -1);

    return result;
}

export function IsCorporateManager() {
    let result = false;

    let loggedInRoles = getUserRoles();

    result = (loggedInRoles.indexOf(userRoles.CorporateManager) !== -1);

    return result;
}

function isNullOrEmpty(str) {
    if (str === null || str === undefined || str === "")
        return true;

    return false;
}

function getUserRoles() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.roles);
    {
        return user.roles;
    }

    return [];
}

function getFunctionAccess() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.functionAccess);
    {
        return user.functionAccess;
    }

    return null;
}

function rgbaToHex(rgba) {
    var parts = rgba.substring(rgba.indexOf("(")).split(","),
        r = parseInt(trim(parts[0].substring(1)), 10),
        g = parseInt(trim(parts[1]), 10),
        b = parseInt(trim(parts[2]), 10),
        a = parseFloat(trim(parts[3].substring(0, parts[3].length - 1))).toFixed(2);

    return ('#' + r.toString(16) + g.toString(16) + b.toString(16) + (a * 255).toString(16).substring(0, 7));
}

function trim(str) {
    return str.replace(/^\s+|\s+$/gm, '');
}

function rgbToHex(rgb) {
    rgb = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
    return (rgb && rgb.length === 4) ? "#" +
        ("0" + parseInt(rgb[1], 10).toString(16)).slice(-2) +
        ("0" + parseInt(rgb[2], 10).toString(16)).slice(-2) +
        ("0" + parseInt(rgb[3], 10).toString(16)).slice(-2) : '';

}

function colorToInt(hex) {
    var result = 0, digitValue;
    hex = hex.substring(1);
    hex = hex.toLowerCase();

    for (var i = 0; i < hex.length; i++) {
        digitValue = '0123456789abcdefgh'.indexOf(hex[i]);
        result = result * 16 + digitValue;
    }

    return result;
}

function intToColor(i) {
    var hex = i.toString(16);
    var length = hex.length;
    if (length < 6) {
        for (length; length < 6; length++) {
            hex = "0" + hex;
        }
    }

    return "#" + hex;
}

function parseDateTime(str) {
    try {
        let y = str.substr(0, 4);
        let m = parseInt(str.substr(5, 2)) - 1;
        let d = str.substr(8, 2);
        let h = str.substr(11, 2);
        let min = str.substr(14, 2);
        let sec = str.substr(17, 2);

        let value = new Date(y, m, d, h, min, sec);

        return value;
    }
    catch{
        return str;
    }
}

function formatDate(date) {
    return moment(date).format("MM/DD/YYYY");
}

function formatDateTime(datetime) {
    return moment(datetime).format("MM/DD/YYYY hh:mm A");
}

function formatTime(datetime) {
    return moment(datetime).format("hh:mm A");
}

function convertToDateTime(str) {
    //let os = getOS();
    //let value = new Date(Date.parse(str));

    //if (os === "iOS") {
    //    let now = new Date();
    //    var offset = now.getTimezoneOffset() / 60;

    //    value.setHours(value.getHours() + offset);
    //}
    let value = parseDateTime(str);

    return value;
}

export function isNotNull(obj) {
    return (obj !== null && obj !== undefined);
}

export function newGuid() {
    let guid = (S4() + S4() + "-" + S4() + "-4" + S4().substr(0, 3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();
    return guid;
}

export function isDesktop() {
    let os = getOS();
    return (os === 'Mac OS' || os === 'Windows' || os === 'Linux') ? true : false;
}

export function S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
}

function getOS() {
    var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows';
    } else if (/Android/.test(userAgent)) {
        os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
        os = 'Linux';
    }

    return os;
}
