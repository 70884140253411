export const bookingConstants = {
    GET_SLOT_ITEMS_REQUEST: 'GET_SLOT_ITEMS_REQUEST',
    GET_SLOT_ITEMS_SUCCESS: 'GET_SLOT_ITEMS_SUCCESS',
    GET_SLOT_ITEMS_FAILURE: 'GET_SLOT_ITEMS_FAILURE',

    GET_STORE_PROFILE_REQUEST: 'APPT_BOOK_GET_STORE_PROFILE_REQUEST',
    GET_STORE_PROFILE_SUCCESS: 'APPT_BOOK_GET_STORE_PROFILE_SUCCESS',
    GET_STORE_PROFILE_FAILURE: 'APPT_BOOK_GET_STORE_PROFILE_FAILURE',

    SEARCH_AVAILABLE_TIME_SLOTS_REQUEST: 'APPT_BOOK_SEARCH_AVAILABLE_TIME_SLOTS_REQUEST',
    SEARCH_AVAILABLE_TIME_SLOTS_SUCCESS: 'APPT_BOOK_SEARCH_AVAILABLE_TIME_SLOTS_SUCCESS',
    SEARCH_AVAILABLE_TIME_SLOTS_FAILURE: 'APPT_BOOK_SEARCH_AVAILABLE_TIME_SLOTS_FAILURE',

    GET_CUSTOMER_INFO_REQUEST: 'APPT_BOOK_GET_CUSTOMER_INFO_REQUEST',
    GET_CUSTOMER_INFO_SUCCESS: 'APPT_BOOK_GET_CUSTOMER_INFO_SUCCESS',
    GET_CUSTOMER_INFO_FAILURE: 'APPT_BOOK_GET_CUSTOMER_INFO_FAILURE',

    SEND_VERIFY_CODE_REQUEST: 'APPT_SEND_VERIFY_CODE_REQUEST',
    SEND_VERIFY_CODE_SUCCESS: 'APPT_SEND_VERIFY_CODE_SUCCESS',
    SEND_VERIFY_CODE_FAILURE: 'APPT_SEND_VERIFY_CODE_FAILURE',

    BOOK_APPOINTMENT_REQUEST: 'APPT_BOOK_SAVE_APPOINTMENT_REQUEST',
    BOOK_APPOINTMENT_SUCCESS: 'APPT_BOOK_SAVE_APPOINTMENT_SUCCESS',
    BOOK_APPOINTMENT_FAILURE: 'APPT_BOOK_SAVE_APPOINTMENT_FAILURE',
};
