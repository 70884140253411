import moment from 'moment';
import { getCollection, getItem, saveItem, getCache, setCache, clearCached } from './service.base';

const baseRoute = "api/company-profile";

export const companyProfileService = {
    getCompanyProfile,
    getBusinessWorkHour,
    saveCompanyProfile,
    getStates,
    getLanguages,
    getTimeIntervals
};

async function saveCompanyProfile(companyProfile) {
    clearCached();

    return await saveItem(`${baseRoute}/save`, companyProfile);
}

function getBusinessWorkHour(companyProfile, d) {
    let day = d.getDay();
    
    switch (day) {
        case 0: //sunday
            return {
                isAvailable: companyProfile.businessHour.isSun,
                workDayStart: moment(new Date(companyProfile.businessHour.sunFromHour)).format("HH:mm"),
                workDayEnd: moment(new Date(companyProfile.businessHour.sunToHour)).format("HH:mm")
            }

        case 1: //Monday
            return {
                isAvailable:  companyProfile.businessHour.isMon,
                workDayStart: moment(new Date(companyProfile.businessHour.monFromHour)).format("HH:mm"),
                workDayEnd: moment(new Date(companyProfile.businessHour.monToHour)).format("HH:mm")
            }

        case 2: //Tuesday
            return {
                isAvailable:  companyProfile.businessHour.isTue,
                workDayStart: moment(new Date(companyProfile.businessHour.tueFromHour)).format("HH:mm"),
                workDayEnd: moment(new Date(companyProfile.businessHour.tueToHour)).format("HH:mm")
            }

        case 3: //Wednesday
            return {
                isAvailable:  companyProfile.businessHour.isWed,
                workDayStart: moment(new Date(companyProfile.businessHour.wedFromHour)).format("HH:mm"),
                workDayEnd: moment(new Date(companyProfile.businessHour.wedToHour)).format("HH:mm")
            }

        case 4: //Thursday
            return {
                isAvailable:  companyProfile.businessHour.isThu,
                workDayStart: moment(new Date(companyProfile.businessHour.thuFromHour)).format("HH:mm"),
                workDayEnd: moment(new Date(companyProfile.businessHour.thuToHour)).format("HH:mm")
            }

        case 5: //Friday
            return {
                isAvailable:  companyProfile.businessHour.isFri,
                workDayStart: moment(new Date(companyProfile.businessHour.friFromHour)).format("HH:mm"),
                workDayEnd: moment(new Date(companyProfile.businessHour.friToHour)).format("HH:mm")
            }

        case 6: //Saturday
            return {
                isAvailable:  companyProfile.businessHour.isSat,
                workDayStart: moment(new Date(companyProfile.businessHour.satFromHour)).format("HH:mm"),
                workDayEnd: moment(new Date(companyProfile.businessHour.satToHour)).format("HH:mm")
            }
    }    
}

async function getCompanyProfile() {
    let companyProfile = getCache("companyProfile");
    
    if (companyProfile === null) {
        companyProfile = await getItem(`${baseRoute}/get-company-profile`);

        setCache("companyProfile", companyProfile);
    }

    return companyProfile;
}

async function getStates() {
    return await getCollection(`${baseRoute}/get-states`);
}

async function getLanguages() {
    return await getCollection(`${baseRoute}/get-languages`);
}

async function getTimeIntervals() {
    let result = [
        { value: 5, text: "5 mins" },
        { value: 10, text: "10 mins" },
        { value: 15, text: "15 mins" },
        { value: 20, text: "20 mins" },
        { value: 30, text: "30 mins" },
        { value: 60, text: "60 mins" }
    ]

    return result;
}