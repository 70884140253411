export const MenuItemTypes = {
    None: "NONE",
    Service: "Service",
    ServicePackage: "ServicePackage",
    ServicePackageItem: "ServicePackageItem",
    QuickService: "QuickService"
}
//export const MenuItemTypes = {
//    None: 0,
//    Service: 1,
//    ServicePackage: 6,
//    ServicePackageItem: 7
//}