import { setStoreConstants as constants } from '../constants';
import { setStoreServices as services} from '../services';
import { doAction } from './action.base';

export const setStoreActions = {
    getAll,
    selectStore,
    setStoreToken,
    save,
    cancelChange,
};

function getAll() {
    return doAction(services.getAll(),
        constants.GET_ALL_REQUEST, constants.GET_ALL_SUCCESS, constants.GET_ALL_FAILURE
    )
}

function selectStore(storeId) {
    return doAction(services.selectStore(storeId),
        constants.SET_STORE_REQUEST, constants.SET_STORE_SUCCESS, constants.SET_STORE_FAILURE
    )
}

function setStoreToken(token) {
    services.setStoreToken(token);
}

function save(dataObj) {
    return doAction(services.save(dataObj),
        constants.SAVE_ITEM_REQUEST, constants.SAVE_ITEM_SUCCESS, constants.SAVE_ITEM_FAILURE
    )
}


function cancelChange() {
    return dispatch => dispatch({ type: constants.CANCEL_CHANGE });
}