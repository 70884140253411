import { dashboardConstants as constants } from '../constants';
import { dashboardServices as services } from '../services';
import { doAction } from './action.base';

export const dashboardActions = {
    getRetiontions,
    getDashboardSummary,
    getCustomerVisits,
    getUpcomingAppointments
};

function getRetiontions() {
    return doAction(services.getRetiontions(),
        constants.GET_RETENTIONS_REQUEST,
        constants.GET_RETENTIONS_SUCCESS,
        constants.GET_RETENTIONS_FAILURE
    )
}

function getDashboardSummary() {
    return doAction(services.getDashboardSummary(),
        constants.GET_DASHBOARD_SUMMARY_REQUEST,
        constants.GET_DASHBOARD_SUMMARY_SUCCESS,
        constants.GET_DASHBOARD_SUMMARY_FAILURE
    )
}

function getCustomerVisits() {
    return doAction(services.getCustomerVisits(),
        constants.GET_CUSTOMER_VISIT_REQUEST,
        constants.GET_CUSTOMER_VISIT_SUCCESS,
        constants.GET_CUSTOMER_VISIT_FAILURE
    )
}

function getUpcomingAppointments() {
    return doAction(services.getUpcomingAppointments(),
        constants.GET_UPCOMING_APPOINMENTS_REQUEST,
        constants.GET_UPCOMING_APPOINMENTS_SUCCESS,
        constants.GET_UPCOMING_APPOINMENTS_FAILURE
    )
}